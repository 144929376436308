import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetOverviewStartup as UsecaseRemoteGetOverviewStartup } from '~/domain/usecases/startup/remote';
// import { GetOverviewStartup as UsecaseReduxGetOverviewStartup } from '~/domain/usecases/startup/redux';

import { RemoteGetOverviewStartup } from '~/data/repository/startup';
// import { ReduxGetOverviewStartup } from '~/data/store/reducer/startup/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetOverviewStartup =
  (): UsecaseRemoteGetOverviewStartup =>
    new RemoteGetOverviewStartup(
      makeApiUrl('/startups/{startup}/overview/'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetOverviewStartup =
  (): UsecaseReduxGetOverviewStartup =>
    new ReduxGetOverviewStartup(); */
