import { CloseIcon } from "@chakra-ui/icons";
import { Avatar, Box } from "@chakra-ui/react";
import React from "react";
import { Typography } from "../UI/Typography";

type Info = {
  id: number;
  logo: string;
  fullname: string;
}

type ChipProps = {
  info: Info;
  onClick?: () => void;
}

export const Chip: React.FC<ChipProps> = ({ info, onClick }) => {
  return (
    <Box
      key={info.id}
      display={'flex'}
      gap={2.5}
      alignItems={'center'}
      justifyContent={'space-between'}
      p={1}
      pr={3}
      bgColor={'white.1'}
      borderWidth={1}
      borderColor='black.5'
      borderRadius="full"
      w='fit-content'
    >
      <Avatar
        src={info.logo}
        size="xs"
        rounded="full"
        border="black.5"
      />

      <Typography
        lineHeight={1}
        variant='b1_Inter_Regular_14px'
      >
        {info.fullname}
      </Typography>
      {onClick && (
        <Box as="button" onClick={onClick} m={0} display={'flex'} alignItems={'center'}>
          <CloseIcon w={2.5} />
        </Box>
      )}
    </Box>
  )
}