import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStartup, iStore } from '~/domain/interfaces/models';
import { makeApiUrl } from '~/main/factories/http';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import { makeRemoteUpdateStartup } from '~/main/factories/usecases/startup/UpdateStartupFactory';
import FormEditStartup from '~/presentation/components/Form/FormEditStartup/FormEditStartup';
import { Button } from '~/presentation/components/UI/Button';
import Form from '~/presentation/components/UI/FormFormik';
import {
  TabPanels,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '~/presentation/components/UI/Tab';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import StringMask from '~/utils/getStringMask';
import { schemaEditStartup } from '~/validators/EditStartup/EditStartupValidator';
import DiscardStartupEdition from './DiscardStartupEdition';
import TeamTab from '../DetailsStartup/TeamTab';
import NoticeTab from '../DetailsStartup/NoticeTab';
import { GetOverviewStartup } from '~/domain/usecases/startup/remote';
import React from 'react';
import { makeRemoteGetOverviewStartup } from '~/main/factories/usecases/startup/GetOverviewStartupFactory';

function genderMap(gender?: 'MALE' | 'FEMALE' | 'OTHER'): string {
  const map: Record<'MALE' | 'FEMALE' | 'OTHER', string> = {
    MALE: 'Masculino',
    FEMALE: 'Feminino',
    OTHER: 'Outro',
  };

  return map[gender ?? 'OTHER'];
}
import { sleep } from '~/utils/sleep';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
}

const EditStartup = ({ isOpen, onClose, startup }: ownProps): JSX.Element => {
  const { initial, validators } = schemaEditStartup;
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [image, setImage] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenDiscard, setIsOpenDiscard] = useState<boolean>(false);
  const [overview, setOverview] = React.useState<GetOverviewStartup.Model>();

  const { accessToken } = useSelector((store: iStore) => store.auth);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [openAddMember, setOpenAddMember] = useState<boolean>(false);

  const startupIsRegular = useMemo(
    () => startup?.situation !== 'REGULAR',
    [startup]
  );

  useEffect(() => {
    startup &&
      makeRemoteGetOverviewStartup()
        .getOverview({ startup: startup.id.toString() })
        .then((response) => {
          setOverview(response);
        });
  }, [startup?.id]);

  useEffect(() => {
    if (startup) {
      const majorMember: iStartup['records'][0]['members'][0] | undefined =
        startup.members.find((member) => member.major);

      const updatedValues = {
        name: startup.name || '',
        city: {
          value: startup.address?.city || '',
          label: startup.address?.city || '',
        },
        cnpj: new StringMask('00.000.000/0000-00').apply(startup.cnpj) ?? '',
        activity: {
          value: startup.activity ?? 0,
          label: startup.activity_.name || 'Unknown',
        },
        cep: startup.address?.zipcode || '',
        address: startup.address?.street || '',
        cargaHoraria: majorMember?.workload || '',
        emailManager: majorMember?.user_.email || '',
        nameManager: majorMember?.user_.fullname || '',
        phoneManager: majorMember?.user_.phone || '',
        roleManager: majorMember?.role || '',
        gender: {
          value: majorMember?.user_.gender || '',
          label: genderMap(majorMember?.user_.gender),
        },
        instagram: startup.instagram || undefined,
        site: startup.site || undefined,
        users: [],
      };
      formik.setValues(updatedValues);
    }
  }, [startup, isOpen]);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    enableReinitialize: true,
    isInitialValid: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      if (startup) {
        try {
          await makeRemoteUpdateStartup().update({
            id: startup.id,
            body: {
              ...values,
              name: values.name,
              fullname: values.nameManager,
              description: 'string',
              cnpj: values?.cnpj?.replace(/[./-]/g, ''),
              activity: Number(values.activity.value),
              supervisor: startup.supervisor,
              address: {
                type: 'APT',
                zipcode: values.cep ?? '',
                province: 'PB',
                city: values.city.value,
                country: 'BRA',
                neighborhood: 'string',
                street: values.address ?? '',
                number: '0',
                complement: 'string',
              },
            },
          });

          if (image !== null) {
            const formData = new FormData();
            formData.append('file', image);
            await axios.post(
              makeApiUrl(`/startups/${startup.id}/logo`),
              formData,
              {
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            );
          }

          if (values?.users && values?.users?.length > 0) {
            values.users.forEach(async (user) => {
              await axios.post(
                makeApiUrl(`/startups/${startup?.id}/user/${user.value}`),
                {
                  role: user.role ?? '',
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
            });
          }
          // eslint-disable-next-line no-console
          console.log('>>>start sleep');
          await sleep(3000);
          // eslint-disable-next-line no-console
          console.log('>>>end sleep');
          showConfirmModal('EDIT_STARTUP');
        } catch (error) {
          showConfirmModal('APPLICATION_ERROR');
        } finally {
          setIsLoading(false);
          onClose();
          makeReduxListStartup().list({});
        }
      }
    },
  });

  const handleOnCloseModal = useCallback(() => {
    if (formik.dirty) {
      setIsOpenDiscard(true);
    } else {
      onClose();
    }
  }, [formik.dirty, onClose]);

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={handleOnCloseModal} isCentered size='xl'>
        <ModalOverlay />
        <ModalContent
          /* maxW={{ base: '100%', md: 'fit-content' }}
          minH={{ base: 'auto', md: 'auto' }}
          position='fixed' */
          minW='865px'
          minH='659px'
          maxW={'fit-content'}
          /* minW={{ base: '739px' }}
          minH={{ base: '700px', md: 'auto' }} */
          position={{ base: 'fixed', md: 'inherit' }}
          top={{ base: '5px', md: 'auto' }}
          bg='#FDFDFD'
          /* maxH={{ base: '90vh', md: 'auto' }} */
          /* overflowY={{ base: 'auto', md: 'initial' }}
          bottom={{ base: '0', md: 'auto' }}
          mt={{ base: '5vh', md: 'auto' }}
          borderTopRadius={{ base: '10px', md: '10px' }}
          borderBottomRadius={{ base: 'none', md: '10px' }} */
        >
          <ModalHeader>Editar Startup</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              maxH={{ base: '625px', md: 'auto' }}
              w={{ base: '739px', md: 'auto' }}
              overflowY={{ base: 'scroll', md: 'initial' }}
            >
              <Form value={formik}>
                <Tabs>
                  <TabsList>
                    <TabsTrigger>Dados gerais</TabsTrigger>
                    {startupIsRegular && <TabsTrigger>Equipe</TabsTrigger>}
                    {startupIsRegular && <TabsTrigger>Editais</TabsTrigger>}
                  </TabsList>
                  <TabPanels>
                    <TabsContent>
                      <FormEditStartup formik={formik} onChange={setImage} />
                    </TabsContent>
                    <TabsContent>
                      <TeamTab
                        startup={startup}
                        overview={overview}
                        edit
                        members={formik.values.users}
                      />
                    </TabsContent>
                    <TabsContent>
                      <NoticeTab startup={startup} overview={overview} />
                    </TabsContent>
                  </TabPanels>
                </Tabs>
              </Form>
            </Box>
          </ModalBody>

          <ModalFooter>
            <HStack justifyContent='space-between' w='100%' spacing='4'>
              <Button variant={'secondary'} onClick={handleOnCloseModal}>
                Cancelar
              </Button>
              <Button
                isLoading={isLoading}
                loadingText='Salvando alterações'
                onClick={() => {
                  formik.handleSubmit();
                  // eslint-disable-next-line no-console
                  console.log('>>> erros', formik.errors);
                }}
                variant={'primary'}
              >
                Salvar Alterações
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DiscardStartupEdition
        isOpen={isOpenDiscard}
        onClose={onClose}
        onCloseDiscard={() => setIsOpenDiscard(false)}
        startup={startup}
      />
    </Box>
  );
};

export default EditStartup;
