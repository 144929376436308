import { ChakraProps, Text } from '@chakra-ui/react';
import React from 'react';
import { iStartup } from '~/domain/interfaces/models';

export interface BadgeSituationProps {
  situation: iStartup['records'][0]['situation'];
}

type SituationColorsMap = Record<
  iStartup['records'][0]['situation'],
  ChakraProps & { text: string }
>;

export const BadgeSituation: React.FC<BadgeSituationProps> = ({
  situation,
}) => {
  const situationColors: SituationColorsMap = {
    PENDING: {
      bgColor: 'white.4',
      color: 'black.2',
      text: 'Pendente',
    },
    REGULAR: {
      bgColor: 'green.4',
      color: 'green.1',
      text: 'Regular',
    },
  };

  const { text: situationMapped } = situationColors[situation];

  return (
    <Text
      px={3}
      py={1.5}
      borderRadius={1}
      fontWeight={500}
      fontSize='sm'
      w='fit-content'
      {...situationColors[situation]}
    >
      {situationMapped}
    </Text>
  );
};
