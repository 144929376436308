import React from 'react';

import { Stack, HStack, Text, Flex } from '@chakra-ui/react';
import * as datefns from 'date-fns';
import Input from '~/presentation/components/UI/Input';
import TextArea from '~/presentation/components/UI/textArea';
import DateInput from '~/presentation/components/UI/DateInput';
import UploadDocs from '../../UploadDocs';
import { iOldFile } from '../../Modals/notice/EditNotice';
import CustomCurrencyInput from '../../UI/CurrencyInput';

interface ownProps {
  formik: any;
  setFiles: (files: File[]) => void;
  oldFiles?: iOldFile[];
  externalFiles?: File[];
}

const FormRegisterNotice = ({
  formik,
  setFiles,
  oldFiles,
  externalFiles,
}: ownProps): JSX.Element => {
  const startDate = new Date();

  return (
    <Stack spacing='4' w='100%' direction='row' gap={'16px'}>
      <Stack spacing='4' w='360px'>
        <HStack spacing='7' alignItems='flex-start'>
          <Input
            id='code'
            name='code'
            label='Número'
            required
            formik={formik}
            placeholder='ex : 01/2023'
          />
          <Input
            id='title'
            name='title'
            label='Identificação'
            required
            formik={formik}
            placeholder='ex : SEECT-PB'
          />
        </HStack>
        <Text fontWeight='bold' fontSize='sm' mb='1'>
          Período de Inscrições
          <span style={{ color: 'red' }}> *</span>
        </Text>
        <HStack spacing='7'>
          <DateInput
            formik={formik}
            required
            name='subscriptionStartAt'
            onChange={(date) => {
              date?.setHours(0, 0, 0, 0);
              formik.setFieldValue('subscriptionStartAt', date);
              if (date && datefns.compareAsc(date, formik.values.end) === 1) {
                date?.setHours(23, 59, 59, 999);
                formik.setFieldValue('subscriptionEndAt', date);
              }
            }}
            selectsStart
            startDate={startDate}
            minDate={startDate}
            dateFormat='dd/MM/yyyy'
          />
          <DateInput
            formik={formik}
            required
            name='subscriptionEndAt'
            onChange={(date) => {
              date?.setHours(23, 59, 59, 999);
              formik.setFieldValue('subscriptionEndAt', date);
            }}
            selectsEnd
            endDate={formik.values.subscriptionEndAt}
            minDate={formik.values.subscriptionStartAt}
            dateFormat='dd/MM/yyyy'
          />
        </HStack>
        <Text fontWeight='bold' fontSize='sm' mb='1'>
          Período de Vigência
          <span style={{ color: 'red' }}> *</span>
        </Text>
        <HStack spacing='7'>
          <DateInput
            formik={formik}
            required
            name='activitiesStartAt'
            onChange={(date) => {
              date?.setHours(0, 0, 0, 0);
              formik.setFieldValue('activitiesStartAt', date);
              if (
                date &&
                datefns.compareAsc(date, formik.values.activitiesEndAt) === 1
              ) {
                formik.setFieldValue('activitiesEndAt', date);
              }
            }}
            selectsStart
            startDate={startDate}
            minDate={startDate}
            dateFormat='dd/MM/yyyy'
          />
          <DateInput
            formik={formik}
            required
            name='activitiesEndAt'
            onChange={(date) => {
              formik.setFieldValue('activitiesEndAt', date);
            }}
            selectsEnd
            endDate={formik.values.activitiesEndAt}
            minDate={formik.values.activitiesStartAt}
            dateFormat='dd/MM/yyyy'
          />
        </HStack>
        <HStack spacing='7' alignItems='flex-start'>
          <CustomCurrencyInput
            id='value'
            name='value'
            label='Valor por projeto'
            type='text'
            required
            formik={formik}
            //placeholder='R$ 0,00'
            value='0,00'
          ></CustomCurrencyInput>
          {/* <Input
            id='value'
            name='value'
            label='Valor pro projeto'
            type='number'
            required
            formik={formik}
            placeholder='R$'
          /> */}
          <Input
            id='vacancies'
            name='vacancies'
            label='Vagas'
            type='number'
            required
            formik={formik}
          />
        </HStack>
        <Flex w='full'>
          <TextArea
            marginTop={'10px'}
            id='description'
            name='description'
            label='Objeto do edital'
            required
            formik={formik}
            resize='none'
            placeholder='ex: Edital de financiamento para Startups da área de saúde.'
            minH={'165px'}
          />
        </Flex>
      </Stack>
      <UploadDocs
        onChange={(files: File[]) => {
          setFiles(files);
        }}
        oldFiles={oldFiles}
        externalFiles={externalFiles}
      />
    </Stack>
  );
};

export default FormRegisterNotice;
