import React, { useState } from 'react';
import * as datefns from 'date-fns';
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Box,
  Text,
  HStack,
  Tfoot,
  TableCaption,
  Button,
} from '@chakra-ui/react';
import RevenueEvaluation from '../RevenueEvaluation';
import {
  IconDelete,
  IconEdit,
  IconMockedStartup1,
} from '~/presentation/base/icons';
import ActionButton from '../ListActions/ActionButton';
import AddStepModal from '../Form/RegisterNotice/AddStepModal';
import { iStep } from '~/domain/interfaces/models';
import getDate from '~/utils/getDate';

interface ownProps {
  stages?: iStep[];
  addStage?: (stage: iStep) => void;
  removeStage?: (index: number) => void;
  updateStage?: (index: number, stage: iStep) => void;
  isEditable?: boolean;
  isEvaluable?: boolean;
}

const Stage = ({
  stages,
  addStage,
  removeStage,
  updateStage,
  isEditable,
  isEvaluable,
}: ownProps) => {
  const [open, setOpen] = React.useState('');
  const [selected, setSelected] = React.useState<number>(-1);

  return (
    <>
      <TableContainer
        /* borderLeft='1px solid #CECECE;'
      borderRight='1px solid #CECECE'
      borderTop='1px solid #CECECE' */

        borderBottomRadius='5px'
        borderTopRadius='5px'
        border='1px solid #CECECE'
        h={356}
        w={'100%'}
        overflowY='auto'
        {...(isEditable && { borderBottom: 'none', borderBottomRadius: '0px' })}
      >
        <Table variant='simple' colorScheme='white'>
          <Thead
            position='sticky'
            top={0}
            zIndex={5}
            backgroundColor='white-3'
            height='50px'
          >
            <Tr>
              <Th>NOME DA ETAPA</Th>
              <Th>DESCRIÇÃO</Th>
              <Th>PERÍODO</Th>
              {isEvaluable && <Th>AVALIAÇÃO</Th>}
              {isEditable && <Th>AÇÕES</Th>}
            </Tr>
          </Thead>
          <Tbody overflowX='scroll'>
            {stages
              ?.sort(
                (a, b) =>
                  new Date(a.startAt).getTime() - new Date(b.startAt).getTime()
              )
              .map((item, index) => (
                <Tr
                  fontSize='sm'
                  color='#747C86'
                  key={index}
                  opacity={datefns.isPast(new Date(item?.endAt)) ? '0.3' : '1'}
                >
                  <Td>
                    <Text
                      display={'-webkit-box'}
                      whiteSpace={'normal'}
                      maxW={'130px'}
                      overflow='hidden'
                      css={{
                        '-webkit-line-clamp': '4',
                        '-webkit-box-orient': 'vertical',
                      }}
                    >
                      {item?.name}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      display={'-webkit-box'}
                      maxW={'200px'}
                      overflow='hidden'
                      whiteSpace={'normal'}
                      css={{
                        '-webkit-line-clamp': '4',
                        '-webkit-box-orient': 'vertical',
                      }}
                    >
                      {item?.description}
                    </Text>
                  </Td>
                  <Td>{`${getDate(item?.startAt)} - ${getDate(
                    item?.endAt
                  )}`}</Td>

                  {isEvaluable && (
                    <Td>
                      <Flex gap={'10px'}>
                        <RevenueEvaluation status='APPROVED'>
                          Aprovado
                        </RevenueEvaluation>
                        <RevenueEvaluation status='REJECTED' isHilighted>
                          Reprovado
                        </RevenueEvaluation>
                      </Flex>
                    </Td>
                  )}
                  {isEditable && (
                    <Td>
                      <HStack spacing='4'>
                        <ActionButton
                          onClick={() => {
                            setSelected(index);
                            setOpen('EDIT');
                          }}
                        >
                          <IconEdit />
                        </ActionButton>
                        <ActionButton
                          onClick={() => removeStage && removeStage(index)}
                        >
                          <IconDelete />
                        </ActionButton>
                      </HStack>
                    </Td>
                  )}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {isEditable && (
        <Box h='64px' border='1px solid #CECECE' borderBottomRadius='5px'>
          <Flex w='100%' justify='flex-end' p={3}>
            <Button
              fontWeight={'normal'}
              bg='white'
              color='#60656D'
              borderWidth={'1px'}
              borderColor='#CECECE'
              onClick={() => {
                setOpen('ADD');
              }}
            >
              Adicionar Etapa
            </Button>
            <AddStepModal
              isOpen={open !== ''}
              onClose={() => setOpen('')}
              type={open}
              stages={stages}
              selected={selected}
              addStage={addStage}
              updateStage={updateStage}
            />
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Stage;
