import React from 'react';

const IconItemNotice = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='39' height='39' rx='4.5' fill='#EDEDED' />
      <rect x='0.5' y='0.5' width='39' height='39' rx='4.5' stroke='#CECECE' />
      <path
        d='M22.9 12.8H14.8V27.2H25.6V15.5H22.9V12.8ZM14.8 11H23.8L27.4 14.6V27.2C27.4 27.6774 27.2104 28.1352 26.8728 28.4728C26.5352 28.8104 26.0774 29 25.6 29H14.8C14.3226 29 13.8648 28.8104 13.5272 28.4728C13.1896 28.1352 13 27.6774 13 27.2V12.8C13 12.3226 13.1896 11.8648 13.5272 11.5272C13.8648 11.1896 14.3226 11 14.8 11ZM16.6 19.1H23.8V20.9H16.6V19.1ZM16.6 22.7H23.8V24.5H16.6V22.7Z'
        fill='#9C9C9C'
      />
    </svg>
  );
};

export default IconItemNotice;
