import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Chip } from '~/presentation/components/Chip';
import { Typography } from '~/presentation/components/UI/Typography';

type StartupsInvitedProps = {
  eventId: number;
};

export const ResponsibleStartups: React.FC<StartupsInvitedProps> = ({
  eventId,
}: StartupsInvitedProps) => {
  const { records: events } = useSelector((store: iStore) => store.event);
  const event = events.find((event) => event.id === eventId);

  return (
    <Stack spacing={3}>
      <Typography variant='h7_Inter_Medium_14px'>Encarregados</Typography>
      <Box display={'flex'} flexWrap={'wrap'} gap={2}>
        {event?.startups
          .map((startup) => (
            <Chip key={startup.id} info={startup} />
          ))}
      </Box>
    </Stack>
  );
};
