import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Event from '~/presentation/pages/event';
import { makeReduxListEvent } from '../../usecases/event/ListEventFactory';
import { makeReduxListNotice } from '../../usecases/notice/ListNoticeFactory';
import { makeReduxListUser } from '../../usecases/user/ListUserFactory';
import { makeReduxListStartup } from '../../usecases/startup/ListStartupFactory';
import { iStore } from '~/domain/interfaces/models';

export const makeEventFactory: React.FC = () => {
  const { selectedStartup: workspaceSelectedStartup, user } = useSelector(
    (store: iStore) => store.auth
  );

  useEffect(() => {
    const queries: any[] = [];
    const defaultQuery = { limit: 999, offset: 0 };

    switch (user?.role_.name) {
      case 'ADMIN':
      case 'SUPERVISOR':
        queries.push(defaultQuery);
        break;

      case 'MENTOR':
        queries.push({ ...defaultQuery, responsable: user.id });
        queries.push(defaultQuery);
        break;

      case 'MANAGER':
      case 'MEMBER':
        queries.push({ ...defaultQuery, startup: workspaceSelectedStartup });
        queries.push(defaultQuery);
        break;

      default:
        queries.push(defaultQuery);
        break;
    }

    queries.forEach((query) => {
      makeReduxListEvent().list({ query });
    });

    makeReduxListNotice().list({ query: { limit: 999, offset: 0 } });
    makeReduxListUser().list({ query: { limit: 999, page: 0 } });
    makeReduxListStartup().list({ query: { limit: 999, offset: 0 } });
  }, [workspaceSelectedStartup, user]);

  return <Event />;
};
