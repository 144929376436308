import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Avatar,
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import { iEvent } from '~/domain/interfaces/models/Event';
import {
  IconDelete,
  IconEdit,
  IconEnter,
  IconOpenFolder,
  IconView,
} from '~/presentation/base/icons';
import ActionButton from '~/presentation/components/ListActions/ActionButton';
import DeleteEventSuccess from '~/presentation/components/Modals/Confirmation/DeleteEventSuccess';
import EditEventSuccess from '~/presentation/components/Modals/Confirmation/EditEventSuccess ';
import RegisterDeliveryEventSuccess from '~/presentation/components/Modals/Confirmation/RegisterDeliveryEventSuccess';
import RegisterEventSuccess from '~/presentation/components/Modals/Confirmation/RegisterEventSuccess';
import RegisterMeetingEventSuccess from '~/presentation/components/Modals/Confirmation/RegisterMeetingEventSuccess';
import RegisterMentoringEventSuccess from '~/presentation/components/Modals/Confirmation/RegisterMentoringEventSuccess';
import SubmitionApplicationError from '~/presentation/components/Modals/Confirmation/SubmitionApplicationError';
import DeleteEvent from '~/presentation/components/Modals/event/DeleteEvent';
import DetailsEvent from '~/presentation/components/Modals/event/DetailsEvent';
import EditEvent from '~/presentation/components/Modals/event/EditEvent';
import RegisterEvent from '~/presentation/components/Modals/event/RegisterEvent';
import Tab from '~/presentation/components/Tab';
import { Typography } from '~/presentation/components/UI/Typography';
import getDate, { getTime } from '~/utils/getDate';
import { ResponsibleStartups } from './responsibleStartups';
import { StartupsInvited } from './startupsInvited';
import { UsersInvited } from './usersInvited';

const Event = (): JSX.Element => {
  const [active, setActive] = React.useState('REUNIÕES');
  const [open, setOpen] = React.useState('');
  const openRegisterEvent = () => {
    setOpen('REGISTER');
  };
  const [selectedEvent, setSelectedEvent] = React.useState<
    iEvent['records'][0] | undefined
  >(undefined);
  const { records } = useSelector((store: iStore) => store.event);
  const [filterByNameValue, setFilterByNameValue] = React.useState<string>('');
  const { user, startups } = useSelector((store: iStore) => store.auth);

  const hasSomeUserInvited = useMemo(() => {
    return (
      records.length > 0 &&
      !!records.find((item) => item.users && item.users.length > 0)
    );
  }, [records]);

  const hasSomeStartupInvited = useMemo(() => {
    return (
      records.length > 0 &&
      !!records.find((item) => item.startups && item.startups.length > 0)
    );
  }, [records]);

  const isEventEditable = (item: iEvent['records'][0]) => {
    switch (user?.role_.name) {
      case 'ADMIN':
        return true;
      case 'SUPERVISOR':
        return item.responsable?.role.name !== 'ADMIN';
      case 'MENTOR':
        return item.responsable?.id === user?.id;
      case 'LEADER':
        return (
          (item.responsable?.role.name === 'LEADER' ||
            item.responsable?.role.name === 'MEMBER') &&
          startups &&
          item.startup_?.id === startups[0]?.id
        );
      case 'MEMBER':
        return item.responsable?.id === user?.id;
      default:
        return false;
    }
  };

  return (
    <Box
      flex='1'
      my='6'
      mx={{ base: 'auto', md: '8' }}
      h='calc(100% - 136px)'
      display={'flex'}
      flexDir={'column'}
      position='relative'
    >
      <Tab
        active={active}
        setActive={setActive}
        tabs={[
          { title: 'REUNIÕES' },
          { title: 'MENTORIAS' },
          { title: 'ENTREGAS' },
        ]}
        filterByName={filterByNameValue}
        setFilterByName={setFilterByNameValue}
        action={() => setOpen('REGISTER')}
        buttonText='Novo Evento'
        searchPlaceholder='Pesquisar eventos...'
      />
      <Accordion
        allowToggle
        borderLeft='1px solid #cfcfcf'
        borderRight='1px solid #cfcfcf'
        borderBottom='1px solid #cfcfcf'
        borderEndStartRadius={8}
        borderEndEndRadius={8}
        height={'100%'}
        maxHeight={'100%'}
        overflowY='auto'
      >
        {active === 'REUNIÕES' && (
          <TableContainer maxH='full' overflowY='auto'>
            <Table variant='simple' colorScheme='white'>
              <Thead position='sticky' top={0} zIndex={5} height='50px'>
                <Tr bgColor='white.3'>
                  {hasSomeUserInvited && <Th />}
                  <Th>Nome do Evento</Th>
                  <Th>Data</Th>
                  <Th>Horário</Th>
                  <Th>Convidado</Th>
                  <Th w={{ lg: '0px', xl: '0px' }}>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {records
                  .filter((item) => item.type === 'MEETING')
                  .filter((item) => {
                    if (filterByNameValue.trim() === '') {
                      return true;
                    }
                    return item.name
                      ?.toLowerCase()
                      .includes(filterByNameValue.toLowerCase());
                  })
                  .map((item, index) => (
                    <AccordionItem as={Fragment} key={item.id}>
                      {({ isExpanded }) => (
                        <>
                          <Tr
                            fontSize={14}
                            fontWeight={400}
                            color='#747C86'
                            key={index}
                          >
                            {hasSomeUserInvited && (
                              <Td maxW={4}>
                                {item.users?.length > 0 && (
                                  <AccordionButton
                                    alignItems='center'
                                    display='flex'
                                    justifyContent='center'
                                    _hover={{ backgroundColor: 'transparent' }}
                                  >
                                    <AccordionIcon />
                                  </AccordionButton>
                                )}
                              </Td>
                            )}
                            <Td>
                              <Box>
                                <Text color='black.1'>{item.name}</Text>
                                <Text color='black.4'>
                                  Organizador:{' '}
                                  {item.responsable?.fullname ||
                                    'Não informado'}
                                </Text>
                              </Box>
                            </Td>
                            <Td>
                              <Text>{getDate(item.date)}</Text>
                            </Td>
                            <Td>
                              <Text>
                                {getTime(item.startAt)} -{' '}
                                {item.endAt
                                  ? getTime(item.endAt)
                                  : 'Não informado'}
                              </Text>
                            </Td>
                            <Td>
                              {item.users?.length === 0 && (
                                <Text color='black.4'>Sem convidados</Text>
                              )}
                              {item.users?.length > 1 ? (
                                <AccordionButton
                                  w='fit-content'
                                  p={0}
                                  _hover={{
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <HStack key={item.users[0]?.id}>
                                    <Avatar
                                      src={item.users[0]?.avatar}
                                      size='xs'
                                    />
                                    <Typography
                                      variant='h7_Inter_Medium_14px'
                                      color='black.2'
                                    >
                                      {item.users[0]?.fullname}
                                    </Typography>
                                    <Typography
                                      color='black.4'
                                      p={1}
                                      variant='b1_Inter_Regular_14px'
                                      rounded='md'
                                      bgColor='gray.100'
                                    >
                                      +{item.users.length - 1}
                                    </Typography>
                                  </HStack>
                                </AccordionButton>
                              ) : (
                                item.users?.map((user) => (
                                  <HStack key={user.id}>
                                    <Avatar src={user.avatar} size='xs' />
                                    <Text color='black.2' fontWeight={500}>
                                      {user.fullname}
                                    </Text>
                                  </HStack>
                                ))
                              )}
                            </Td>
                            <Td flex={1}>
                              <HStack spacing='4'>
                                <ActionButton disabled={!item.link}>
                                  <Link to={item.link} target='_blank'>
                                    <IconEnter />
                                  </Link>
                                </ActionButton>
                                <ActionButton
                                  onClick={() => {
                                    setOpen('DETAILS');
                                    setSelectedEvent(item);
                                  }}
                                >
                                  <IconView />
                                </ActionButton>
                                <ActionButton
                                  onClick={() => {
                                    setOpen('EDIT');
                                    setSelectedEvent(item);
                                  }}
                                  isDisabled={!isEventEditable(item)}
                                >
                                  <IconEdit />
                                </ActionButton>
                                <ActionButton
                                  actions={['ADMIN', 'SUPERVISOR']}
                                  resourcers={['ADMIN', 'SUPERVISOR']}
                                  onClick={() => {
                                    setOpen('DELETE');
                                    setSelectedEvent(item);
                                  }}
                                  isDisabled={!isEventEditable(item)}
                                >
                                  <IconDelete />
                                </ActionButton>
                              </HStack>
                            </Td>
                          </Tr>
                          {isExpanded && (
                            <Tr>
                              <Td />
                              <Td colSpan={Object.keys(records).length}>
                                <UsersInvited eventId={item.id} />
                              </Td>
                            </Tr>
                          )}
                        </>
                      )}
                    </AccordionItem>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {active === 'MENTORIAS' && (
          <TableContainer maxH='full' overflowY='auto'>
            <Table variant='simple' colorScheme='white'>
              <Thead
                position='sticky'
                top={0}
                zIndex={5}
                backgroundColor='white.3'
                height='50px'
              >
                <Tr>
                  {hasSomeStartupInvited && <Th></Th>}
                  <Th>Nome do Evento</Th>
                  <Th>Data</Th>
                  <Th>Horário</Th>
                  <Th>Convidados</Th>
                  <Th w={{ md: '0px', lg: '0px' }}>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {records
                  .filter((item) => item.type === 'MENTORING')
                  .filter((item) => {
                    if (filterByNameValue.trim() === '') {
                      return true;
                    } else if (filterByNameValue.trim() !== '') {
                      return `${item.name}`
                        .toLowerCase()
                        .startsWith(filterByNameValue.toLowerCase());
                    }
                  })
                  .map((item, index) => (
                    <AccordionItem as={Fragment} key={item.id}>
                      {({ isExpanded }) => (
                        <>
                          <Tr
                            fontSize={14}
                            fontWeight={400}
                            color='#747C86'
                            key={index}
                          >
                            {hasSomeStartupInvited && (
                              <Td maxW={4}>
                                {item.startups.length > 0 && (
                                  <AccordionButton
                                    alignItems={'center'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    _hover={{ backgroundColor: 'transparent' }}
                                  >
                                    <AccordionIcon />
                                  </AccordionButton>
                                )}
                              </Td>
                            )}
                            <Td>
                              <Box>
                                <Text color='black.1'>{item.name}</Text>
                                <Text color='black.4'>
                                  Mentor: {item.responsable?.fullname}
                                </Text>
                              </Box>
                            </Td>
                            <Td>
                              <Text>{getDate(item.date)}</Text>
                            </Td>
                            <Td>
                              <Text>
                                {getTime(item.startAt)} -
                                {item.endAt
                                  ? getTime(item.endAt)
                                  : 'Não informado'}
                              </Text>
                            </Td>
                            <Td>
                              {item.startups.length === 0 && (
                                <Text color='black.4'>Sem convidados</Text>
                              )}
                              {item.startups.length > 1 ? (
                                <AccordionButton
                                  w='fit-content'
                                  p={0}
                                  _hover={{
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <HStack key={item.startups[0].id}>
                                    <Avatar
                                      src={item.startups[0].logo}
                                      size={'xs'}
                                    />
                                    <Typography
                                      variant='h7_Inter_Medium_14px'
                                      color='black.2'
                                    >
                                      {item.startups[0]?.fullname}
                                    </Typography>
                                    <Typography
                                      color='black.4'
                                      p={1}
                                      variant='b1_Inter_Regular_14px'
                                      rounded='md'
                                      bgColor='gray.100'
                                    >
                                      +{item.startups.length - 1}
                                    </Typography>
                                  </HStack>
                                </AccordionButton>
                              ) : (
                                <>
                                  {item.startups.map((user) => (
                                    <HStack key={user.id}>
                                      <Avatar
                                        src={item.startups[0].logo}
                                        size={'xs'}
                                      />
                                      <Text color='black.2' fontWeight={500}>
                                        {item.startups[0]?.fullname}
                                      </Text>
                                    </HStack>
                                  ))}
                                </>
                              )}
                            </Td>
                            <Td>
                              <HStack spacing='4'>
                                <ActionButton disabled={!item.link}>
                                  <Link to={item.link} target='_blank'>
                                    <IconEnter />
                                  </Link>
                                </ActionButton>
                                <ActionButton
                                  onClick={() => {
                                    setOpen('DETAILS'), setSelectedEvent(item);
                                  }}
                                >
                                  <IconView />
                                </ActionButton>

                                <ActionButton
                                  onClick={() => {
                                    setOpen('EDIT'), setSelectedEvent(item);
                                  }}
                                  isDisabled={!isEventEditable(item)}
                                >
                                  <IconEdit />
                                </ActionButton>

                                <ActionButton
                                  onClick={() => {
                                    setOpen('DELETE'), setSelectedEvent(item);
                                  }}
                                  isDisabled={!isEventEditable(item)}
                                >
                                  <IconDelete />
                                </ActionButton>
                              </HStack>
                            </Td>
                          </Tr>
                          {isExpanded && (
                            <Tr>
                              <Td />
                              <Td colSpan={Object.keys(records).length}>
                                <StartupsInvited eventId={item.id} />
                              </Td>
                            </Tr>
                          )}
                        </>
                      )}
                    </AccordionItem>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {active === 'ENTREGAS' && (
          <TableContainer maxH='full' overflowY='auto'>
            <Table variant='simple' colorScheme='white'>
              <Thead
                position='sticky'
                top={0}
                zIndex={5}
                backgroundColor='white.3'
                height='50px'
              >
                <Tr>
                  {hasSomeStartupInvited && <Th />}
                  <Th>Nome da entrega</Th>
                  <Th>Etapa Associada</Th>
                  <Th>Enviar até</Th>
                  <Th w={{ md: '0px', lg: '0px' }}>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {records
                  .filter((item) => item.type === 'DELIVERY')
                  .filter((item) => {
                    if (filterByNameValue.trim() === '') {
                      return true;
                    } else if (filterByNameValue.trim() !== '') {
                      return (
                        `${item.name}`
                          .toLowerCase()
                          .search(filterByNameValue.toLowerCase()) !== -1
                      );
                    }
                  })
                  .map((item, index) => (
                    <AccordionItem as={Fragment} key={item.id}>
                      {({ isExpanded }) => (
                        <>
                          <Tr
                            fontSize={14}
                            fontWeight={400}
                            color='#747C86'
                            key={index}
                          >
                            {hasSomeStartupInvited && (
                              <Td maxW={4}>
                                {item.startups.length > 0 && (
                                  <AccordionButton
                                    alignItems={'center'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    _hover={{ backgroundColor: 'transparent' }}
                                  >
                                    <AccordionIcon />
                                  </AccordionButton>
                                )}
                              </Td>
                            )}
                            <Td>
                              <Box>
                                <Text color='black.1'>{item.name}</Text>
                                <Text color='black.4'>
                                  Edital associado:{' '}
                                  {item.notice
                                    ? `Nº ${item?.notice?.code}-${item?.notice?.title}`
                                    : 'Não associado'}
                                </Text>
                              </Box>
                            </Td>
                            <Td>{item.step?.name}</Td>
                            <Td
                              overflow='hidden'
                              maxWidth='200px'
                              textOverflow='ellipsis'
                            >
                              <Text>
                                {getDate(item.date)}, às {getTime(item.date)}
                              </Text>
                            </Td>
                            <Td>
                              <HStack spacing='4'>
                                <ActionButton disabled={!item.notice}>
                                  <Link
                                    to={{
                                      pathname: '/documentos',
                                      state: {
                                        path: [
                                          { name: 'STARTUPS', id: 0 },
                                          {
                                            name: `Inscrição ${item.startup_?.name} no Edital ${item.notice?.code}`,
                                            id: item?.folder,
                                          },
                                        ],
                                      },
                                    }}
                                  >
                                    <IconOpenFolder />
                                  </Link>
                                </ActionButton>
                                <ActionButton
                                  onClick={() => {
                                    setOpen('DETAILS'), setSelectedEvent(item);
                                  }}
                                >
                                  <IconView />
                                </ActionButton>
                                <ActionButton
                                  onClick={() => {
                                    setOpen('EDIT'), setSelectedEvent(item);
                                  }}
                                  isDisabled={!isEventEditable(item)}
                                >
                                  <IconEdit />
                                </ActionButton>
                                <ActionButton
                                  onClick={() => {
                                    setOpen('DELETE'), setSelectedEvent(item);
                                  }}
                                  isDisabled={!isEventEditable(item)}
                                >
                                  <IconDelete />
                                </ActionButton>
                              </HStack>
                            </Td>
                          </Tr>
                          {isExpanded && (
                            <Tr>
                              <Td />
                              <Td colSpan={Object.keys(records).length}>
                                <ResponsibleStartups eventId={item.id} />
                              </Td>
                            </Tr>
                          )}
                        </>
                      )}
                    </AccordionItem>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Accordion>
      <DetailsEvent
        isOpen={open === 'DETAILS'}
        onClose={() => setOpen('')}
        event={selectedEvent}
      ></DetailsEvent>
      <RegisterEvent isOpen={open === 'REGISTER'} onClose={() => setOpen('')} />
      <EditEvent
        isOpen={open === 'EDIT'}
        onClose={() => setOpen('')}
        event={selectedEvent}
      />
      <DeleteEvent
        isOpen={open === 'DELETE'}
        onClose={() => setOpen('')}
        event={selectedEvent}
      />
      <RegisterEventSuccess
        onOpenRegister={openRegisterEvent}
      ></RegisterEventSuccess>
      <EditEventSuccess></EditEventSuccess>
      <RegisterMeetingEventSuccess
        onOpenRegister={openRegisterEvent}
      ></RegisterMeetingEventSuccess>
      <RegisterMentoringEventSuccess
        onOpenRegister={openRegisterEvent}
      ></RegisterMentoringEventSuccess>
      <RegisterDeliveryEventSuccess
        onOpenRegister={openRegisterEvent}
      ></RegisterDeliveryEventSuccess>
      <SubmitionApplicationError></SubmitionApplicationError>
      <DeleteEventSuccess event={selectedEvent}></DeleteEventSuccess>
    </Box>
  );
};

export default Event;
