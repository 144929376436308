import {
  Avatar,
  Flex,
  Box,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  CloseButton,
  Circle,
} from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';
import { iStartup } from '~/domain/interfaces/models';
import GenericModalLayout from '~/presentation/components/Modals/GenericModalLayout';
import StringMask from '~/utils/getStringMask';
import { Label } from '~/presentation/components/UI/Label';
import { useHistory } from 'react-router-dom';
import { Link } from '~/presentation/components/UI/Link';
import { Typography } from '~/presentation/components/UI/Typography';
import { TagStatus } from '~/presentation/components/UI/TagStatus';
import { getRoleByName } from '~/utils/getRoleByName';
import TeamTab from './TeamTab';
import { GetOverviewStartup } from '~/domain/usecases/startup/remote';
import { makeRemoteGetOverviewStartup } from '~/main/factories/usecases/startup/GetOverviewStartupFactory';
import NoticeTab from './NoticeTab';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  startup?: iStartup['records'][0];
}

const DetailsStartup = ({ isOpen, onClose, startup }: ownProps) => {
  //eslint-disable-next-line no-console
  console.log('genero', startup?.members[0].user_.gender);

  const [overview, setOverview] = React.useState<GetOverviewStartup.Model>();

  const history = useHistory();

  const handleLinkClick = (e: { preventDefault: () => void }) => {
    e.preventDefault(); // Prevent the default link behavior

    history.push('/documentos', {
      path: [
        { name: 'STARTUPS', id: 0 },
        { name: startup?.name, id: startup?.folder },
      ],
    });
  };

  const getMainLeader = useCallback(() => {
    if (startup?.members) {
      for (const member of startup.members) {
        /**Todo mudar para 'LEADER' quando léo corrigir*/
        if (member.role === 'LEADER' && member.major) {
          return member;
        }
      }
    }
    return undefined;
  }, [startup]);

  const userGender: { [key: string]: string } = {
    MALE: 'Masculino',
    FEMALE: 'Feminino',
    OTHER: 'Outro',
  };

  useEffect(() => {
    startup &&
      makeRemoteGetOverviewStartup()
        .getOverview({ startup: startup.id.toString() })
        .then((response) => {
          setOverview(response);
        });
  }, [startup?.id]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW='813px' minH='659px'>
        <ModalHeader pt='24px' pb='21px'>
          <Flex justify='space-between' h='36px' align='center'>
            Detalhes da Startup
            <CloseButton w='36px' h='36px' onClick={() => onClose()} />
          </Flex>
        </ModalHeader>
        <ModalBody py='0px'>
          <Tabs variant='unstyled'>
            <TabList color='black.2' gap='12px'>
              <Tab p='0px 0px 10px 0px' fontSize='14px' fontWeight='600'>
                Dados Gerais
              </Tab>

              {/* Tab de quipes */}
              <Tab
                p='0px 0px 10px 0px'
                fontSize='14px'
                fontWeight='600'
                display={startup?.situation !== 'PENDING' ? 'none' : 'block'}
              >
                Equipe
              </Tab>

              {/* Tab de editais */}
              <Tab
                p='0px 0px 10px 0px'
                fontSize='14px'
                fontWeight='600'
                display={startup?.situation !== 'PENDING' ? 'none' : 'block'}
              >
                Editais
              </Tab>
            </TabList>

            <TabIndicator
              mt='-1.5px'
              height='3px'
              bg='black.1'
              borderRadius='1px'
            />

            <TabPanels>
              <TabPanel p='0px' pt='20px'>
                <Flex
                  flexDirection={'row'}
                  w={'100%'}
                  justifyContent={'space-between'}
                  h='503px'
                >
                  <Flex w={'100%'} gap='32px'>
                    <Avatar
                      w='80px'
                      h='80px'
                      size='lg'
                      name={startup?.name}
                      src={startup?.logo}
                    />
                    <Divider
                      orientation='vertical'
                      borderColor='white.4'
                      borderWidth='1px'
                      height='100%'
                    />
                    <Flex flexDirection={'row'} gap='20px'>
                      <Flex
                        flexDirection={'column'}
                        gap='12px'
                        flex='1'
                        minW='260px'
                      >
                        <Label>Nome da Startup</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          whiteSpace='normal'
                          color='black.2'
                          mb='8px'
                        >
                          {startup?.name}
                        </Typography>

                        <Label>Endereço</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={
                            startup?.address.street ? 'black.2' : '#9C9C9C'
                          }
                          whiteSpace='normal'
                          mb='8px'
                        >
                          {startup?.address.street
                            ? startup?.address.street
                            : 'Não informado'}
                        </Typography>

                        <Label>Site</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={startup?.site ? 'black.2' : '#9C9C9C'}
                          whiteSpace='normal'
                          mb='8px'
                        >
                          {startup?.site ? startup?.site : 'Não informado'}
                        </Typography>

                        <Label>Nome do responsável</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          whiteSpace='normal'
                          color='black.2'
                          mb='8px'
                        >
                          {getMainLeader()?.user_.fullname}
                        </Typography>

                        <Label>E-mail do responsável</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          whiteSpace='normal'
                          color='black.2'
                          mb='8px'
                        >
                          {getMainLeader()?.user_.email}
                        </Typography>

                        <Label>Função do responsável</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={getMainLeader()?.role ? 'black.2' : '#9C9C9C'}
                          whiteSpace='normal'
                          mb='8px'
                        >
                          {getMainLeader()?.role
                            ? getMainLeader()?.role
                            : 'Não informado'}
                        </Typography>

                        <Label>Documentos</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          whiteSpace='normal'
                        >
                          <Link
                            label='Ver documentos da startup'
                            to='/documentos'
                            onClick={handleLinkClick}
                          />
                        </Typography>
                      </Flex>
                      <Flex
                        flexDirection={'column'}
                        gap='12px'
                        flex='1'
                        minW='160px'
                      >
                        <Label>Segmento</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          whiteSpace='normal'
                          color='black.2'
                          mb='8px'
                        >
                          {startup?.activity_.name}
                        </Typography>

                        <Label>Cidade</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={startup?.address.city ? 'black.2' : '#9C9C9C'}
                          whiteSpace='normal'
                          mb='8px'
                        >
                          {startup?.address.city
                            ? startup?.address.city
                            : 'Não informado'}
                        </Typography>

                        <Label>Instagram</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={startup?.instagram ? 'black.2' : '#9C9C9C'}
                          whiteSpace='normal'
                          mb='8px'
                        >
                          {startup?.instagram
                            ? startup?.instagram
                            : 'Não informado'}
                        </Typography>

                        <Label>Gênero</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={
                            getMainLeader()?.user_.gender
                              ? 'black.2'
                              : '#9C9C9C'
                          }
                          mb='8px'
                        >
                          {
                            userGender[
                              getMainLeader()?.user_.gender ?? 'Não informado'
                            ]
                          }
                        </Typography>

                        <Label>Telefone</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={
                            getMainLeader()?.user_.phone ? 'black.2' : '#9C9C9C'
                          }
                          mb='8px'
                        >
                          {getMainLeader()?.user_.phone
                            ? new StringMask('(00) 00000-0000').apply(
                                getMainLeader()?.user_.phone
                              )
                            : 'Não informado'}
                        </Typography>

                        <Label>Carga horária</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={
                            getMainLeader()?.user_.workload
                              ? 'black.2'
                              : '#9C9C9C'
                          }
                          mb='8px'
                        >
                          {getMainLeader()?.user_.workload
                            ? getMainLeader()?.user_.workload
                            : 'Não informado'}
                        </Typography>

                        {/**
                        <Label>Etapa Atual</Label>
                       Todo Solicitar para adequar com o novo back*/
                        /* <Typography
                    variant='b1_Inter_Regular_14px'
                    color={
                      startup?.applications.find(
                        (el) => el.status === 'APPROVED'
                      )?.steps[0]?.name
                        ? '#303950'
                        : '#9C9C9C'
                    }
                  >
                    {startup?.applications.find(
                      (el) => el.status === 'APPROVED'
                    )?.steps[0]?.name || 'Não foi incubado'}
                  </Typography> */}

                        <Label>Situação</Label>
                        <Flex align='center' gap='6px'>
                          <Circle
                            size='6px'
                            bg={
                              startup?.situation === 'PENDING'
                                ? 'black.5'
                                : 'green.2'
                            }
                          />
                          <Typography
                            variant='b1_Inter_Regular_14px'
                            color='black.2'
                          >
                            {startup?.situation === 'PENDING'
                              ? 'Pendente'
                              : 'Regular'}
                          </Typography>
                        </Flex>
                      </Flex>

                      <Flex
                        flexDirection={'column'}
                        gap='12px'
                        flex='1'
                        minW={'max-content'}
                      >
                        <Label>CNPJ</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={startup?.cnpj ? 'black.2' : '#9C9C9C'}
                          mb='8px'
                        >
                          {startup?.cnpj
                            ? new StringMask('00.000.000/0000-00').apply(
                                startup?.cnpj
                              )
                            : 'Não informado'}
                        </Typography>

                        <Label>CEP</Label>
                        <Typography
                          variant='b1_Inter_Regular_14px'
                          color={
                            startup?.address.zipcode ? 'black.2' : '#9C9C9C'
                          }
                          mb='8px'
                        >
                          {startup?.address.zipcode
                            ? startup?.address.zipcode
                            : 'Não informado'}
                        </Typography>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </TabPanel>

              <TabPanel p='0px' pt='20px'>
                <TeamTab
                  startup={startup}
                  mainLeader={getMainLeader()}
                  overview={overview}
                />
              </TabPanel>

              <TabPanel p='0px' pt='20px'>
                <NoticeTab startup={startup} overview={overview} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DetailsStartup;
