import React from 'react';
import WarningModal from '~/presentation/components/Modals/WarningModal';
import { iStartup } from '~/domain/interfaces/models';
import { Typography } from '~/presentation/components/UI/Typography';
import { HStack, Stack } from '@chakra-ui/react';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseDiscard: () => void;
  startup?: iStartup['records'][0];
  id?: number;
}

const DiscardStartupEdition = ({
  isOpen,
  onClose,
  onCloseDiscard,
  startup,
}: ownProps): JSX.Element => {
  return (
    <WarningModal
      isOpen={isOpen}
      onClose={onCloseDiscard}
      title='Descartar alterações?'
      question={
        <Stack gap={'0'}>
          <HStack gap={'1'}>
            <Typography variant='b1_Inter_Regular_14px'>
              Há alteraçoes não salvas na startup
            </Typography>
            <Typography variant='h7_Inter_Medium_14px'>
              {startup?.name}
              {'. '}
            </Typography>
          </HStack>
          <Typography variant='b1_Inter_Regular_14px'>
            Tem certeza de que quer fechar esta janela e descartá-las?
          </Typography>
        </Stack>
      }
      action={() => {
        onClose();
        onCloseDiscard();
      }}
      actionText='Fechar e descartar'
      cancelActionText='Continuar editando'
    />
  );
};

export default DiscardStartupEdition;
