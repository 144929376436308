import React, { Component, useEffect } from 'react';
import * as datefns from 'date-fns';
import {
  Button,
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Flex,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import Input from '../../UI/Input';
import DateInput from '~/presentation/components/UI/DateInput';
import TextArea from '../../UI/textArea';
import { iStep } from '~/domain/interfaces/models';
import { schemaRegisterStage } from '~/validators/RegisterStage/RegisterStageValidator';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  type: string;
  stages?: iStep[];
  selected: number;
  addStage?: (stage: iStep) => void;
  updateStage?: (index: number, stage: iStep) => void;
}

const AddStepModal = ({
  isOpen,
  onClose,
  type,
  stages,
  selected,
  addStage,
  updateStage,
}: ownProps): JSX.Element => {
  const { initial, validators } = schemaRegisterStage;

  useEffect(() => {
    if (type === 'EDIT') {
      const stage = stages?.find((el, i) => i === selected);
      if (stage) {
        formik.setFieldValue('name', stage.name);
        formik.setFieldValue('description', stage.description);
        formik.setFieldValue(
          'start',
          new Date(stage.startAt.replace('.000Z', ''))
        );
        formik.setFieldValue('end', new Date(stage.endAt.replace('.000Z', '')));
      }
    }
  }, [type]);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: validators,
    onSubmit: async (values, { resetForm }) => {
      type === 'ADD'
        ? addStage &&
          addStage({
            ...values,
            startAt:
              datefns.format(values.start, 'yyyy-MM-dd') + 'T00:00:00.000Z',
            endAt: datefns.format(values.end, 'yyyy-MM-dd') + 'T00:00:00.000Z',
          })
        : updateStage &&
          updateStage(selected, {
            ...values,
            startAt:
              datefns.format(values.start, 'yyyy-MM-dd') + 'T00:00:00.000Z',
            endAt: datefns.format(values.end, 'yyyy-MM-dd') + 'T00:00:00.000Z',
          });

      resetForm({ values: initial });
      onClose();
    },
  });

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          formik.resetForm();
        }}
      >
        <ModalOverlay />
        <ModalContent w='384px' h='511px'>
          <ModalHeader>Inserir Etapa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap='20px'>
              <Input
                id='name'
                name='name'
                label='Nome da Etapa'
                required
                formik={formik}
              />
              <Stack>
                <Text fontWeight='bold' fontSize='sm' mb='1'>
                  Período
                </Text>
                <HStack spacing='7'>
                  <DateInput
                    formik={formik}
                    name='start'
                    required
                    onChange={(date) => {
                      formik.setFieldValue('start', date);
                      if (
                        date &&
                        datefns.compareAsc(date, formik.values.end) === 1
                      ) {
                        formik.setFieldValue('end', date);
                      }
                    }}
                    selectsStart
                    startDate={formik.values.start}
                    endDate={formik.values.end}
                    dateFormat='dd/MM/yyyy'
                  />
                  <DateInput
                    formik={formik}
                    name='end'
                    required
                    onChange={(date) => formik.setFieldValue('end', date)}
                    selectsEnd
                    startDate={formik.values.start}
                    endDate={formik.values.end}
                    minDate={formik.values.start}
                    dateFormat='dd/MM/yyyy'
                  />
                </HStack>
              </Stack>
              <Flex w='full'>
                <TextArea
                  height={'120px'}
                  id='description'
                  name='description'
                  label='Descrição'
                  required
                  formik={formik}
                />
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent='start' paddingBottom='22px'>
            <HStack justifyContent='space-between' w='100%' spacing='4'>
              <Button
                w={'100px'}
                variant='outline'
                color='#303950'
                borderColor='#CECECE'
                onClick={() => {
                  onClose();
                  formik.resetForm();
                }}
              >
                <Text fontWeight='500' fontSize='14px'>
                  Cancelar
                </Text>
              </Button>
              <Button
                w={'100px'}
                id='submit'
                bg='#303950'
                fontWeight={'500'}
                _hover={{ background: '#788FC9' }}
                _active={{ background: '#303950' }}
                color='white'
                onClick={() => formik.handleSubmit()}
              >
                <Text fontWeight='500' fontSize='14px'>
                  Concluir
                </Text>
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AddStepModal;
