import React from "react";

type IconDropListProps = {
  color?: string;
};

const IconDropList: React.FC<IconDropListProps> = ({ color }) => {
  return (
    <svg
      width='8'
      height='6'
      viewBox='0 0 8 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.90573 5.0002L0.305725 2.4002C-0.0109418 2.08353 -0.0816084 1.7212 0.093725 1.3132C0.268392 0.904529 0.580725 0.700195 1.03073 0.700195H6.18072C6.63072 0.700195 6.94306 0.904529 7.11772 1.3132C7.29306 1.7212 7.22239 2.08353 6.90573 2.4002L4.30572 5.0002C4.20572 5.1002 4.09739 5.17519 3.98073 5.22519C3.86406 5.2752 3.73906 5.3002 3.60573 5.3002C3.47239 5.3002 3.34739 5.2752 3.23073 5.22519C3.11406 5.17519 3.00573 5.1002 2.90573 5.0002Z'
        fill={color ?? '#222222'}
      />
    </svg>
  );
};

export default IconDropList;
