import { Avatar, Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxSetStartupAuth } from '~/main/factories/usecases/auth/SetStartupAuthFactory';
import { withAccess } from '~/presentation/hooks/acess';

const WorkspaceSelector = () => {
  const { startups, selectedStartup } = useSelector(
    (store: iStore) => store.auth
  );

  return (
    <Flex
      flexDirection='column'
      borderRight='1px'
      borderColor='#5C5C5C'
      bg='#222222'
      w='64px'
      minW='64px'
      align='center'
      pt='32px'
      gap='12px'
    >
      {startups?.map((item, index) => {
        const outlinePos = (31 + index * 52).toString() + 'px';

        return (
          <>
            <Box
              display={item.id === selectedStartup?.id ? 'block' : 'none'}
              w='44px'
              h='44px'
              position='absolute'
              top={outlinePos}
              bg='#f8f8f80'
              border='2px'
              borderRadius='5px'
              borderColor='#F8F8F8'
            />
            <Flex
              display={item.logo ? 'flex' : 'none'}
              w='40px'
              h='40px'
              borderRadius='5px'
              border={item.id === selectedStartup?.id ? '2px' : '1px'}
              borderColor={
                item.id === selectedStartup?.id ? '#222222' : '#9C9C9C'
              }
              bg={item.id === selectedStartup?.id ? '#222222' : '#9C9C9C'}
              overflow='hidden'
              justify='center'
              align='center'
              onClick={() => {
                makeReduxSetStartupAuth().setStartup({
                  startup: startups[index],
                });
              }}
              cursor='pointer'
            >
              <Avatar
                key={index}
                borderRadius='5px'
                w='40px'
                h='40px'
                name={item.name}
                src={item.logo}
                bg='transparent'
              />
            </Flex>

            <Flex
              display={item.logo ? 'none' : 'flex'}
              w='40px'
              h='40px'
              borderRadius='5px'
              border={item.id === selectedStartup?.id ? '2px' : '1px'}
              borderColor={
                item.id === selectedStartup?.id ? '#222222' : '#9C9C9C'
              }
              bg={item.id === selectedStartup?.id ? '#222222' : '#9C9C9C'}
              overflow='hidden'
              justify='center'
              align='center'
              onClick={() => {
                makeReduxSetStartupAuth().setStartup({
                  startup: startups[index],
                });
              }}
              cursor='pointer'
            >
              <Avatar
                key={index}
                borderRadius='5px'
                w='40px'
                h='40px'
                name={item.name}
                src={item.logo}
              />
            </Flex>
          </>
        );
      })}
    </Flex>

    /* 
    <Popover
      placement={
        useMediaQuery('(max-width: 560px)')[0] ? 'bottom' : 'right-start'
      }
    >
      <PopoverTrigger>
        <Flex
          fontSize='14px'
          fontWeight='500'
          my='5'
          px='8'
          py='10px'
          w='100%'
          h='44px'
          gap='10px'
          cursor='pointer'
          color='#E2E2E2'
          align='center'
          _hover={{
            bg: '#484848',
            color: '#FDFDFD',
          }}
          _focus={{
            boxShadow: '0px 0px 15px 0px #1997F380',
            borderWidth: '2px',
            borderColor: '#1997F3',
          }}
        >
          <Avatar
            borderRadius='3px'
            border='1px'
            color='#9C9C9C'
            bg='#ffffff0'
            w='24px'
            h='24px'
            size='sm'
            name={selected?.name}
            src={selected?.logo}
          />
          {selected?.name}
          <ChevronDownIcon />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        border='1px'
        borderColor='#9C9C9C'
        borderRadius='5px'
        w='256px'
      >
        <PopoverBody p='0px' bg='#FDFDFD' borderRadius='5px'>
          {startups?.map((item, index) => {
            return (
              <>
                <Divider
                  display={index === 0 ? 'none' : 'block'}
                  borderColor='#9C9C9C'
                />
                <Flex
                  px='12px'
                  h='40px'
                  borderRadius='5px'
                  align='center'
                  key={index}
                  bg='#FDFDFD'
                  fontSize='14px'
                  color={item === selected ? '#222222' : '#484848'}
                  fontWeight={item === selected ? '500' : '400'}
                  gap='10px'
                  onClick={() => {
                    setSelected(item);
                  }}
                  cursor='pointer'
                  _hover={{
                    bg: '#EDEDED',
                  }}
                  _focus={{
                    boxShadow: '0px 0px 15px 0px #1997F380',
                    borderWidth: '2px',
                    borderColor: '#1997F3',
                  }}
                >
                  <Avatar
                    borderRadius='3px'
                    border='1px'
                    color={item === selected ? '#484848' : '#9C9C9C'}
                    bg='#F8F8F8'
                    w='24px'
                    h='24px'
                    size='sm'
                    name={item.name}
                    src={item.logo}
                  />
                  {item.name}
                  <CheckIcon
                    display={item === selected ? 'block' : 'none'}
                    position='absolute'
                    right='15px'
                    color='#1997F3'
                  />
                </Flex>
              </>
            );
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover> */
  );
};

export default withAccess(WorkspaceSelector);
