import React from 'react';

interface ownProps {
  isHilighted?: boolean;
  color?: string;
}

const IconTicked = ({ isHilighted, color }: ownProps) => {
  return (
    <svg
      width='9'
      height='9'
      viewBox='0 0 9 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.94198 1.5758L7.72134 0.5L3.3779 6.01133L1.12301 3.62547L0 4.81318L3.486 8.5L8.94198 1.5758Z'
        fill={color ? color : '#5B7A17'}
      />
    </svg>
  );
};

export default IconTicked;
