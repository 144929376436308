import {
  Box,
  Center,
  Divider,
  HStack,
  useBreakpointValue,
  VStack
} from '@chakra-ui/react';
import cepPromise from 'cep-promise';
import { Field } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import cidadesPB from '~/utils/cidadesPB';
import FormItem from '../../UI/FormFormik/formItem';
import { Input } from '../../UI/NewInput';
import { Select } from '../../UI/NewSelect';
import UploadImage from '../../UploadImage';
import { CepResponse } from '../RegisterStartup/FormRegisterStartup';

interface ownProps {
  formik: any;
  onChange?: (files: File) => void;
  oldData?: { name?: string; url?: string };
}

const FormEditStartup = ({
  formik,
  onChange,
  oldData,
}: ownProps): JSX.Element => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { records } = useSelector((store: iStore) => store.activity);
  const [cepResponse, setCepResponse] = useState<CepResponse | undefined>(
    undefined
  );

  const genderOptions = [
    { value: 'MALE', label: 'Masculino' },
    { value: 'FEMALE', label: 'Feminino' },
    { value: 'OTHER', label: 'Outro' },
  ];

  const activityOptions = records.map((activity) => ({
    value: activity.id,
    label: activity.name,
  }));

  const cityOptions = cidadesPB.map((city) => ({
    value: city,
    label: city,
  }));

  function isValidCEP(cepInput: string) {
    return /^[0-9]{5}-[0-9]{3}$/.test(cepInput);
  }

  const handleActivityChange = (val: { value: any }) => {
    const selectedValue = val ? val.value : '';
    formik.setFieldValue('activity', Number(selectedValue));
  };

  const handleOnBlur = () => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.cep);
        setCepResponse(cepResponseData);
        formik.setFieldValue(
          'address',
          `${cepResponseData.street}, ${cepResponseData.neighborhood}, ${cepResponseData.city}`
        );
      } catch {
        setCepResponse(undefined);
      }
    }

    if (isValidCEP(formik.values.cep)) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  };

  const Container = isMobile ? VStack : HStack;

  return (
    <Container
      maxH={isMobile ? '625px' : 'auto'}
      overflowY={isMobile ? 'scroll' : 'initial'}
      padding={isMobile ? '16px' : '0'}
    >
      <Container
        justifyContent='space-between'
        alignItems={isMobile ? 'center' : 'flex-start'}
        gap={'32px'}
        height={isMobile ? '100%' : 'auto'}
      >
        <UploadImage onChange={onChange} oldData={oldData} />
        {!isMobile ? (
          <Center height='500px'>
            <Divider
              orientation={'vertical'}
              borderColor='#CECECE'
            />
          </Center>
        ) : null}
        <VStack spacing='4' width={{ base: '100%', md: '280px' }}>
        <FormItem name='name'>
          <Field
            as={Input}
            id='name'
            name='name'
            label='Nome da Startup'
            isRequired
          />
        </FormItem>
        <FormItem name='address'>
          <Field
            as={Input}
            id='address'
            name='address'
            label='Endereço'
            isOptional
          />
        </FormItem>
        <FormItem name='site'>
          <Field
            as={Input}
            id='site'
            name='site'
            label='Site'
            placeholder='https://'
            isOptional
          />
        </FormItem>
        <FormItem name='nameManager'>
          <Field
            as={Input}
            id='nameManager'
            name='nameManager'
            label='Nome do responsável'
            isRequired
          />
        </FormItem>
        <FormItem name='emailManager'>
          <Field
            as={Input}
            id='emailManager'
            name='emailManager'
            label='E-mail do responsável'
            isRequired
          />
        </FormItem>
        <FormItem name='roleManager'>
          <Field
            as={Input}
            id='roleManager'
            name='roleManager'
            label='Função do responsável'
            placeholder='ex.: Gerente Administrativo'
            isOptional
          />
        </FormItem>
      </VStack>

      <VStack spacing='4' width={{ base: '100%', md: '180px' }}>
        <Box minW='100%'>
          <FormItem name='activity'>
            <Field
              as={Select}
              id='activity'
              name='activity'
              label='Segmento'
              options={activityOptions}
              placeholder='Escolha uma opção'
              isRequired
              onChange={handleActivityChange}
            />
          </FormItem>
        </Box>
        <Box minW='100%'>
          <FormItem name='city'>
            <Field
              as={Select}
              id='city'
              name='city'
              label='Cidade'
              placeholder='Escolha uma opção'
              options={cityOptions}
              isRequired
            />
          </FormItem>
        </Box>
        <FormItem name='instagram'>
          <Field
            as={Input}
            id='instagram'
            name='instagram'
            label='Instagram'
            placeholder='@'
            isOptional
          />
        </FormItem>
        <Box minW='100%'>
          <FormItem name='gender'>
            <Field
              as={Select}
              id='gender'
              name='gender'
              label='Gênero'
              options={genderOptions}
              placeholder='Escolha uma opção'
              isRequired
            />
          </FormItem>
        </Box>
        <FormItem name='phoneManager'>
          <Field
            as={Input}
            id='phoneManager'
            name='phoneManager'
            label='Telefone'
            mask='phone'
            placeholder='(__) _ ____-____'
            isOptional
          />
        </FormItem>
        <FormItem name='cargaHoraria'>
          <Field
            as={Input}
            id='cargaHoraria'
            name='cargaHoraria'
            label='Carga horária'
            isOptional
          />
        </FormItem>
      </VStack>

      <VStack spacing='4' width={{ base: '100%', md: '180px' }}>
        <FormItem name='cnpj'>
          <Field
            as={Input}
            id='cnpj'
            name='cnpj'
            label='CNPJ'
            mask='cnpj'
            placeholder='__.___.___/____-__'
            isOptional
          />
        </FormItem>
        <FormItem name='cep'>
          <Field
            as={Input}
            id='cep'
            name='cep'
            label='CEP'
            placeholder='_____-___'
            mask='cep'
            isOptional
            onBlurCapture={handleOnBlur}
          />
        </FormItem>
      </VStack>
      </Container>
    </Container>
  );
};

export default FormEditStartup;
