/* eslint-disable react/prop-types */
'use client';

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabsProvider,
} from '@chakra-ui/react';
import * as React from 'react';
import { Typography } from '../Typography';

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsProvider>,
  React.ComponentPropsWithoutRef<typeof TabsProvider>
>(({ className, ...props }, ref) => (
  <TabsProvider ref={ref} w={'full'} colorScheme='black' {...props}>
    {props.children}
  </TabsProvider>
));
Tabs.displayName = TabsProvider.displayName;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabList>,
  React.ComponentPropsWithoutRef<typeof TabList>
>(({ className, ...props }, ref) => (
  <TabList
    ref={ref}
    w={'full'}
    display={'inline-flex'}
    h={'fit-content'}
    alignItems={'center'}
    justifyContent={'flex-start'}
    bgColor={'white.1'}
    color={'black.1'}
    borderColor={'transparent'}
    {...props}
  />
));
TabsList.displayName = TabList.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof Tab>,
  React.ComponentPropsWithoutRef<typeof Tab>
>(({ className, ...props }, ref) => (
  <Tab
    ref={ref}
    display={'inline-flex'}
    alignItems={'center'}
    justifyContent={'center'}
    whiteSpace={'nowrap'}
    rounded={'sm'}
    py={4}
    px={6}
    fontSize={'sm'}
    fontWeight={'medium'}
    transition={'all'}
    borderColor={'transparent'}
    bgColor={'white'}
    _hover={{
      bgColor: 'white.2',
      color: 'blue.2',
    }}
    _focus={{
      ring: 2,
      ringColor: 'black.1',
      ringOffset: 2,
    }}
    _disabled={{
      pointerEvents: 'none',
      opacity: 0.5,
      bgColor: 'white.1',
      color: 'black.5',
    }}
    {...props}
  >
    <Typography variant='h6_Inter_SemiBold_14px' color='black.2'>
      {props.children}
    </Typography>
  </Tab>
));
TabsTrigger.displayName = Tab.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabPanel>,
  React.ComponentPropsWithoutRef<typeof TabPanel>
>(({ className, ...props }, ref) => (
  <TabPanel
    ref={ref}
    p={0}
    mt={4}
    _focusVisible={{
      ringColor: 'black.1',
      ringOffset: 2,
    }}
    {...props}
  />
));
TabsContent.displayName = TabPanel.displayName;

export { TabPanels, Tabs, TabsContent, TabsList, TabsTrigger };
