import { useState, useEffect } from 'react';
import React from 'react';
import { Box, Flex, FormLabel, Text } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import Select from '~/presentation/components/UI/select';

interface Option {
  value: string;
  label: string;
}

const Tags = ({ label, onDelete }: { label: string; onDelete: () => void }) => (
  <Flex
    width='177px'
    display='flex'
    flexDirection='row'
    alignItems='center'
    padding='6px 14px'
    gap='10px'
    background='#E9ECEE'
    border='1px solid rgba(91, 100, 107, 0.5)'
    borderRadius='5px'
    position='relative'
  >
    <Text>{label}</Text>
    <Box
      as='button'
      onClick={onDelete}
      position='absolute'
      right='4px'
      top='4px'
      bg='transparent'
      border='none'
      cursor='pointer'
    >
      <SmallCloseIcon />
    </Box>
  </Flex>
);

interface ownProps {
  onChange: (guests: number[]) => void;
  formik: any;
  mode: 'users' | 'startups';
}

const GuestSelect = ({ onChange, formik, mode }: ownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedOptions([]);
    formik.setFieldValue('guests', []);
  }, [mode]);

  const { user } = useSelector((store: iStore) => store.auth);
  const { records: userRecords } = useSelector((store: iStore) => store.user);
  const { records: startupRecords } = useSelector(
    (store: iStore) => store.startup
  );
  const { records: noticeRecords } = useSelector(
    (store: iStore) => store.notice
  );

  const mentorNotices = noticeRecords.filter((notice) =>
    (notice.mentors || []).some((mentor) => mentor.user?.id === user?.id)
  );
  const mentorNoticeIds = mentorNotices.map((notice) => notice.id);

  const mentorStartups = startupRecords.filter((startup) => {
    const applicationNotices = (startup.applications || []).map(
      (app) => app.notice_?.id
    );

    return applicationNotices.some((noticeId) =>
      mentorNoticeIds.includes(noticeId)
    );
  });

  const options =
    mode === 'users'
      ? userRecords.map((record) => ({
          value: record.id,
          label: record.fullname,
        }))
      : user?.role_.name === 'MENTOR'
      ? mentorStartups.map((startup) => ({
          value: startup.id,
          label: startup.name,
        }))
      : startupRecords.map((record) => ({
          value: record.id,
          label: record.name,
        }));

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = Number(event.target.value);
    if (!selectedOptions.includes(selectedOption)) {
      const updatedOptions = [...selectedOptions, selectedOption];
      setSelectedOptions(updatedOptions);
      formik.setFieldValue('guests', updatedOptions);
    }
  };

  const handleDelete = (index: number) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
    formik.setFieldValue('guests', updatedOptions);
  };

  return (
    <Box w='360px'>
      <FormLabel fontWeight='700' fontSize='14px' mb='0px'>
        Convidados<span style={{ color: 'red' }}>*</span>
      </FormLabel>
      <Select
        marginBottom='12px'
        id='guests'
        name='guests'
        w='360px'
        placeholder='Selecione'
        bg='#F9F8FA'
        onChange={handleSelect}
        value=''
        label=''
        formik={formik}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <Flex rowGap='8px' flexDirection='column' mt='4px' w='360px'>
        {options
          .filter((el) => selectedOptions.includes(el.value))
          .map((el, index) => (
            <Tags
              key={el.value}
              label={el.label}
              onDelete={() => handleDelete(index)}
            />
          ))}
      </Flex>
    </Box>
  );
};

export default GuestSelect;
