import * as yup from 'yup';

export const schemaRegisterNotice = {
  initial: {
    code: '',
    title: '',
    description: '',
    vacancies: '',
    value: '0',
    subscriptionStartAt: new Date(),
    subscriptionEndAt: new Date(new Date().setHours(23, 59, 59, 999)),
    activitiesStartAt: new Date(),
    activitiesEndAt: new Date(),
  },
  validators: yup.object({
    code: yup.string().required('Campo obrigatório'),
    title: yup.string().required('Campo obrigatório'),
    description: yup.string().required('Campo obrigatório'),
    vacancies: yup.string().required('Campo obrigatório'),
    value: yup
      .string()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Campo obrigatório'),
    subscriptionStartAt: yup.date(),
    subscriptionEndAt: yup.date().required('Campo obrigatório'),
    activitiesStartAt: yup.date().required('Campo obrigatório'),
    activitiesEndAt: yup.date().required('Campo obrigatório'),
  }),
};
