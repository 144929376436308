import { validateCNPJ } from 'validations-br';
import * as yup from 'yup';

export type EditStartupValues = yup.InferType<typeof validators>;

const validators = yup.object({
  name: yup.string().required('Campo obrigatório'),
  cnpj: yup
    .string()
    .matches(
      /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/,
      'CNPJ inválido'
    )
    .test('is-cnpj', 'CNPJ inválido', (cnpj) =>
      cnpj ? validateCNPJ(cnpj) : true
    ),
  site: yup.string().url('Site inválido').optional(),
  address: yup.string(),
  cep: yup.string().matches(/^(58\d{3})-\d{3}$/, 'CEP inválido'),
  nameManager: yup.string().required('Campo obrigatório'),
  roleManager: yup.string(),
  emailManager: yup
    .string()
    .required('Campo obrigatório')
    .email('Email inválido'),
  activity: yup
    .object()
    .shape({
      value: yup.string().required('Campo obrigatório'),
      label: yup.string().optional(),
    })
    .nullable()
    .required('Campo obrigatório'),
  city: yup
    .object()
    .shape({
      value: yup.string().required('Campo obrigatório'),
      label: yup.string().optional(),
    })
    .nullable()
    .required('Campo obrigatório'),
  instagram: yup.string().optional(),
  gender: yup
    .object()
    .shape({
      value: yup
        .string()
        .required('Campo obrigatório')
        .oneOf(['MALE', 'FEMALE', 'OTHER'], 'Selecione uma opção válida'),
      label: yup.string().optional(),
    })
    .nullable()
    .required('Campo obrigatório'),
  phoneManager: yup.string().nullable(),
  cargaHoraria: yup.string(),
  users: yup.array().of(
    yup.object().shape({
      value: yup.string().required('Campo obrigatório'),
      label: yup.string().required('Campo obrigatório'),
      role: yup
        .string()
        .required('Campo obrigatório')
        .oneOf(['MEMBER', 'LEADER']),
      avatar: yup.string().optional(),
      email: yup.string().email('Email inválido').required('Campo obrigatório'),
    })
  ),
});

export const schemaEditStartup = {
  initial: {
    name: '',
    cnpj: '',
    site: '',
    instagram: '',
    address: '',
    cep: '',
    nameManager: '',
    roleManager: '',
    emailManager: '',
    activity: {
      value: '',
      label: '',
    },
    city: {
      value: '',
      label: '',
    },
    gender: null,
    phoneManager: '',
    cargaHoraria: '',
    users: [],
  } as EditStartupValues,
  validators,
};
