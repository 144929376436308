import React, { useEffect, useMemo } from 'react';
import { Avatar, HStack, Stack, Tag } from '@chakra-ui/react';
import { Select } from '../../UI/NewSelect';
import FormItem from '../../UI/FormFormik/formItem';
import { Field, useFormikContext } from 'formik';
import { Typography } from '../../UI/Typography';
import { IconTicked } from '~/presentation/base/icons';
import { iStartup } from '~/domain/interfaces/models';
import { components } from 'react-select';

interface iAddMemberToStartup {
  startup: string;
  user: string;
  role: string;
}

interface iUser {
  value: string;
  label: string;
  role: string;
}

interface ownProps {
  startup?: iStartup['records'][0];
  setSelectedUserId?: (id: string) => void;
  selectedUserId: string;
  usersSet: iUser[];
}

export const Option = (props: any) => {
  const { data, children } = props;
  return (
    <components.Option {...props}>
      <HStack gap={1}>
        <Avatar
          borderRadius='3px'
          size='sm'
          src={data.itemImage}
          name={data.label}
        />
        <Typography variant='b2_Inter_Regular_14px'>{children}</Typography>
      </HStack>
    </components.Option>
  );
};

const FormAddMemberToStartup = ({
  startup,
  setSelectedUserId,
  selectedUserId,
  usersSet,
}: ownProps): JSX.Element => {
  enum ActiveRoleTag {
    LEADER = 'LEADER',
    MEMBER = 'MEMBER',
  }
  const [selectedRoleTag, setSelectedRoleTag] = React.useState<string>('');
  const { setFieldValue, isValid, dirty, touched, errors, values } =
    useFormikContext<iAddMemberToStartup>();
  const startupOption = [{ value: startup?.id, label: startup?.name }];

  /* const membersOptions = useMemo(
    () =>
      usersSet.map((user) => ({
        value: user.value,
        label: member?.user_?.fullname,
        itemImage: member?.user_?.avatar,
      })) || [],
    [startup]
  ); */

  useEffect(() => {
    if (startup) {
      setFieldValue('startup', startupOption);
    }
    if (selectedUserId) {
      setFieldValue('user', selectedUserId);
    }
  }, [startup, isValid, touched, dirty, errors]);

  return (
    <Stack gap='5' ml={0}>
      <FormItem name='startup'>
        <Field
          as={Select}
          id='startup'
          name='startup'
          label='Startup'
          isDisabled
          /* components={{
            Option,
            SingleValue: (props: any) => {
              return (
                <HStack>
                  <Avatar
                    borderRadius='3px'
                    size='sm'
                    src={props.data.image as string}
                    name={props.data.label}
                  />
                  <Typography variant='b2_Inter_Regular_14px' color='black'>
                    {props.data.label as string}
                  </Typography>
                </HStack>
              );
            },
          }} */
          variant='subtle'
          isRequired
          options={startupOption}
        />
      </FormItem>
      <FormItem name='user'>
        <Field
          as={Select}
          id='user'
          name='user'
          label='Usuário'
          isSearchable
          value={
            usersSet.find(
              (opt) => opt.value.toString() === selectedUserId.toString()
            ) || null
          }
          options={usersSet}
          placeholder='Pesquisar usuários...'
          /* components={{
            Option: (props: any) => (
              <Option
                {...props}
                itemImage={props.data.itemImage as string}
                name={props.data.label as string}
              />
            ),
            SingleValue: (props: any) => {
              if (!props.data || !props.data.label) {
                return (
                  <Typography variant='b2_Inter_Regular_14px' color='gray.500'>
                    Pesquisar um usuário...
                  </Typography>
                );
              }

              return (
                <HStack>
                  <Avatar
                    borderRadius='3px'
                    size='sm'
                    src={props.data.itemImage as string}
                    name={props.data.label as string}
                  />
                  <Typography variant='b2_Inter_Regular_14px' color='black'>
                    {props.data.label as string}
                  </Typography>
                </HStack>
              );
            },
          }} */
          onChange={(option: any) => {
            setSelectedUserId?.(option.value);
          }}
          disabled
          isRequired
        />
      </FormItem>
      <FormItem name='role'>
        <Stack>
          <Typography variant='h6_Inter_SemiBold_14px'>
            Permissão<span style={{ color: '#C23422' }}>*</span>
          </Typography>
          <HStack gap={'2'}>
            <Field
              as={Tag}
              id='leader-role'
              key='tag-leader'
              name='leader'
              variant='outline'
              colorScheme={
                selectedRoleTag === ActiveRoleTag.LEADER ? '#0873F1' : '#9C9C9C'
              }
              size='lg'
              onClick={() => {
                setSelectedRoleTag(ActiveRoleTag.LEADER);
                setFieldValue('role', 'LEADER');
              }}
              border={
                selectedRoleTag === ActiveRoleTag.LEADER
                  ? '2px solid #0873F1'
                  : '1px solid #9C9C9C'
              }
              borderRadius='5px'
              backgroundColor={
                selectedRoleTag === ActiveRoleTag.LEADER ? '#E4F6FF' : '#FDFDFD'
              }
            >
              <HStack>
                {selectedRoleTag === ActiveRoleTag.LEADER && (
                  <IconTicked color='#0873F1' />
                )}
                <Typography
                  variant='b2_Inter_Regular_14px'
                  color={
                    selectedRoleTag === ActiveRoleTag.LEADER
                      ? '#0873F1'
                      : '#484848'
                  }
                >
                  Gestor
                </Typography>
              </HStack>
            </Field>
            <Field
              as={Tag}
              id='member-role'
              key='tag-member'
              label='Permissão'
              isRequired
              name='member'
              variant='outline'
              colorScheme={
                selectedRoleTag === ActiveRoleTag.MEMBER ? '#0873F1' : '#9C9C9C'
              }
              size='lg'
              onClick={() => {
                setSelectedRoleTag(ActiveRoleTag.MEMBER);
                setFieldValue('role', 'MEMBER');
              }}
              border={
                selectedRoleTag === ActiveRoleTag.MEMBER
                  ? '2px solid #0873F1'
                  : '1px solid #9C9C9C'
              }
              borderRadius='5px'
              backgroundColor={
                selectedRoleTag === ActiveRoleTag.MEMBER ? '#E4F6FF' : '#FDFDFD'
              }
            >
              <HStack gap={'2'}>
                {selectedRoleTag === ActiveRoleTag.MEMBER && (
                  <IconTicked color='#0873F1' />
                )}
                <Typography
                  variant='b2_Inter_Regular_14px'
                  color={
                    selectedRoleTag === ActiveRoleTag.MEMBER
                      ? '#0873F1'
                      : '#484848'
                  }
                >
                  Membro
                </Typography>
              </HStack>
            </Field>
          </HStack>
        </Stack>
      </FormItem>
    </Stack>
  );
};

export default FormAddMemberToStartup;
