import { iAuth } from '~/domain/interfaces/models/Auth';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';
import { AuthActions } from './actions';

export const initialState: iAuth = {
  loading: false,
  error: false,
};

const reducer = (state = initialState, action: AuthActions): iAuth => {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return { ...state, loading: true };
    case AuthTypes.LOGIN_FAILED:
      return { ...state, loading: false, error: true };
    case AuthTypes.LOGIN_SUCCESS: {
      const selectedStartup = action.payload.startups?.length
        ? action.payload.startups[0]
        : undefined;

      return {
        ...action.payload,
        loading: false,
        error: false,
        selectedStartup,
      };
    }
    case AuthTypes.REFRESH: {
      // eslint-disable-next-line no-console
      console.log('>>> REFRESH');
      const { accessToken, refreshToken, user } = action.payload;

      return { ...state, accessToken, refreshToken, user, loading: true };
    }
    case AuthTypes.SETSTARTUP: {
      const roleName = action.payload.startup.role;
      let user = state.user;
      user = user
        ? { ...user, role_: { ...user.role_, name: roleName, descr: roleName } }
        : undefined;

      return { ...state, selectedStartup: action.payload.startup, user };
    }
    case AuthTypes.LOGOUT:
      return state;
    default:
      return state;
  }
};

export default reducer;
