import React, { useEffect, useRef, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import {
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  Grid,
  GridItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Center,
  Spinner,
} from '@chakra-ui/react';
import makeData from '~/utils/makeData';
import {
  IconArrowLeft,
  IconArrowRight,
  IconDownloadDocs,
  IconFirstPage,
  IconFolder,
  IconLastPage,
} from '~/presentation/base/icons';
import { renderIcons } from '../Arquivos';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxListFolderContentDocument } from '~/main/factories/usecases/document/ListFolderContentDocumentFactory';
import axios from 'axios';
import saveAs from 'file-saver';
import { makeApiUrl } from '~/main/factories/http';
import DeleteDocument from '../DeleteDocument';

interface FolderProps {
  id: number;
  parent: number;
  children?: string;
  name: string;
  folders: FolderProps[];
}
interface activeFolder {
  folders: {
    id: number;
    parent: number;
    children?: string;
    name: string;
    folders?: FolderProps[];
  }[];
  files: {
    id: number;
    folder: number;
    token: string;
    filename: string;
    mimetype: string;
    size: number;
    enabled: boolean;
  }[];
}

interface ownProps {
  active: 'STARTUPS' | 'INCUBADORA' | 'MENTORIA' | 'ENTREGAS';
  path: { name: string; id: number }[];
  setPath: (value: { name: string; id: number }[]) => void;
  setCanRegister: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  addFiles?: (files: File[]) => void;
  loadingContent: boolean;
}

const getGridData = () => {
  let gridComputedStyle;
  const grid = document.getElementById('grid');
  if (grid) gridComputedStyle = window.getComputedStyle(grid);

  return (
    gridComputedStyle && {
      gridRowCount: gridComputedStyle
        .getPropertyValue('grid-template-rows')
        .split(' ').length,
      gridColumnCount: gridComputedStyle
        .getPropertyValue('grid-template-columns')
        .split(' ').length,
    }
  );
};

const List = ({
  active,
  path,
  setPath,
  setCanRegister,
  setLoading,
  addFiles,
  loadingContent,
}: ownProps): JSX.Element => {
  const grid = useRef<HTMLDivElement>(null);
  const [activeFolder, setActiveFolder] = React.useState<activeFolder>({
    folders: [],
    files: [],
  });
  const [listFiles, setListFiles] = React.useState(false);
  const {
    noticeFolders,
    startupFolders,
    folderContent,
    mentoringFolders,
    deliveryFolders,
    loading,
  } = useSelector((state: iStore) => state.document);

  const handleCanRegister = (value: boolean) => {
    setCanRegister(value);
    setListFiles(value);
  };

  useEffect(() => {
    if (active !== path[0].name) {
      setPath([{ name: active, id: 0 }]);
    }

    if (active === 'STARTUPS' && path.length === 1) {
      setActiveFolder({
        folders:
          startupFolders?.records.map((el) => {
            return {
              id: el.id,
              parent: el.parent,
              name: el.name,
              folders: [],
            };
          }) || [],
        files: [],
      });
      handleCanRegister(false);
    }

    if (active === 'INCUBADORA' && path.length === 1) {
      setActiveFolder({
        folders:
          noticeFolders?.records.map((el) => {
            return {
              id: el.id,
              parent: el.parent,
              name: el.name,
              folders: [],
            };
          }) || [],
        files: [],
      });
      handleCanRegister(false);
    }

    if (active === 'MENTORIA') {
      setActiveFolder({
        folders:
          mentoringFolders?.records.map((el) => {
            return {
              id: el.id,
              parent: el.parent,
              name: el.name,
              folders: [],
            };
          }) || [],
        files: [],
      });
      handleCanRegister(false);
    }

    if (active === 'ENTREGAS') {
      setActiveFolder({
        folders:
          deliveryFolders?.records.map((el) => {
            return {
              id: el.id,
              parent: el.parent,
              name: el.name,
              folders: [],
            };
          }) || [],
        files: [],
      });
      handleCanRegister(false);
    }
  }, [active, path, loading, startupFolders]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (path.length > 1 && folderContent) {
      setActiveFolder(folderContent);
      setCanRegister(folderContent.permissions[0]?.write || false);

      if (folderContent.files.length > 0 && !folderContent.folders.length)
        setListFiles(true);
      else setListFiles(false);

      /*** The older version of the active register button*/
      /* handleCanRegister(
        (!folderContent.folders.length && folderContent.files.length > 0) ||
          path[path.length - 1].name === 'Geral'
      ); */
      /* The older version of the loading state was not working properly */
      //setLoading(false);
    }
  }, [folderContent]);

  useEffect(() => {
    if (path.length > 1)
      makeReduxListFolderContentDocument().listFolderContent({
        id: path[path.length - 1].id,
      });
  }, [path]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        acessor: 'firstName',
      },
    ],
    []
  );

  const tableInstance = !listFiles
    ? useTable(
        {
          columns,
          data: activeFolder?.folders,
          initialState: { pageIndex: 0 },
        },
        usePagination
      )
    : useTable(
        {
          columns,
          data: activeFolder?.files,
          initialState: { pageIndex: 0 },
        },
        usePagination
      );

  const {
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    if (!grid.current) return;

    const resizeObserver = new ResizeObserver(() => {
      const gridData = getGridData();
      if (gridData && grid.current?.clientHeight) {
        setPageSize(
          gridData.gridColumnCount *
            (Math.floor((grid.current?.clientHeight - 32) / 84) || 1)
        );
      }
    });

    resizeObserver.observe(grid.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Box
      h='calc(100% - 169px);'
      border='1px solid #cfcfcf'
      borderTop='none'
      borderBottomRadius='10px'
      position='relative'
    >
      {loadingContent ? (
        <Center h='calc(100% - 64px)'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      ) : (
        <Grid
          id='grid'
          h='calc(100% - 64px)'
          pl='8'
          p='4'
          gap='3'
          borderBottom='1px solid #cfcfcf'
          gridTemplateColumns='repeat(auto-fill, minmax(261px, 1fr))'
          gridTemplateRows={`repeat(auto-fill, minmax(72px, 72px))`}
          ref={grid}
          overflow='hidden'
        >
          {!listFiles
            ? page.map((row: any, i: number) => {
                return (
                  <GridItem
                    key={row.original.name + i}
                    minW='261px'
                    maxW='350px'
                    h='72px'
                    border='1px solid #CECECE'
                    borderRadius='10px'
                    cursor='pointer'
                    onClick={() => {
                      /* The older version of the loading state was not working properly */
                      /* setLoading(true); */
                      setPath([
                        ...path,
                        { name: row.original.name, id: row.original.id },
                      ]);
                    }}
                  >
                    <Flex mx='4' my='3' gap='4'>
                      <Box pt='1'>
                        <IconFolder />
                      </Box>
                      <Box color='#60656D' fontSize='md' pt='1'>
                        <Text fontWeight='medium'>{row.original.name}</Text>
                        {/* <Text display={'none'} fontSize='sm'>
                        20MB
                      </Text> */}
                      </Box>
                    </Flex>
                  </GridItem>
                );
              })
            : page.map((doc: any, i: number) => {
                // eslint-disable-next-line no-console
                console.log('...doc', doc);
                return (
                  <GridItem
                    key={doc.original.id}
                    minW='261px'
                    maxW='350px'
                    h='72px'
                    border='1px solid #CECECE'
                    borderRadius='10px'
                    cursor='pointer'
                  >
                    <Popover placement='right-start'>
                      <PopoverTrigger>
                        <Flex
                          h='100%'
                          px='4'
                          py='3'
                          gap='4'
                          alignItems='center'
                        >
                          {renderIcons(`${doc.original.mimetype}`)}
                          <Box
                            w='calc(100% - 40px)'
                            color='#60656D'
                            fontSize='md'
                          >
                            <Text
                              fontWeight='medium'
                              overflow='hidden'
                              whiteSpace='nowrap'
                              textOverflow='ellipsis'
                            >
                              {doc.original.filename}
                            </Text>
                            <Text fontSize='sm'>{`${doc.original.mimetype?.toUpperCase()} - ${
                              doc.original.size
                            }`}</Text>
                          </Box>
                        </Flex>
                      </PopoverTrigger>
                      <PopoverContent
                        bg='white'
                        w='160px'
                        fontSize='sm'
                        lineHeight='140%'
                      >
                        <PopoverArrow bg='white' />
                        <PopoverBody
                          p='0'
                          border='1px solid #CECECE'
                          borderRadius='5px'
                        >
                          <Flex
                            gap='2'
                            h='40px'
                            align='center'
                            px='3'
                            borderBottom='1px solid #CECECE'
                            onClick={() => {
                              axios
                                .get(makeApiUrl(`/files/${doc.original.id}`), {
                                  responseType: 'blob',
                                })
                                .then((response) => {
                                  saveAs(response.data, doc.original.filename);
                                });
                            }}
                          >
                            <IconDownloadDocs />
                            <Text>Fazer download</Text>
                          </Flex>
                          {/* <RenameDocument />
                           */}
                          <DeleteDocument
                            actions={['ADMIN']}
                            resourcers={['ADMIN']}
                            name={doc.original.filename}
                            id={doc.original.id}
                            pathId={path[path.length - 1].id}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </GridItem>
                );
              })}
        </Grid>
      )}

      <Flex h='64px' justifyContent='center' alignItems='center'>
        <Flex alignItems='center' gap='3'>
          <Tooltip label='First Page'>
            <IconButton
              bg='#F9F8FA'
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<IconFirstPage />}
              aria-label={''}
              size='sm'
            />
          </Tooltip>
          <Tooltip label='Previous Page'>
            <IconButton
              bg='#F9F8FA'
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<IconArrowLeft />}
              aria-label={''}
              size='sm'
            />
          </Tooltip>
          {/* <Text color='#747C86' fontSize='sm'>{`${pageIndex * pageSize + 1} - ${
            pageIndex * pageSize + page.length
          } de ${folder.STARTUPS.length}`}</Text> */}
          <Tooltip label='Next Page'>
            <IconButton
              bg='#F9F8FA'
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<IconArrowRight />}
              aria-label={''}
              size='sm'
            />
          </Tooltip>
          <Tooltip label='Last Page'>
            <IconButton
              bg='#F9F8FA'
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<IconLastPage />}
              aria-label={''}
              size='sm'
            />
          </Tooltip>
        </Flex>
      </Flex>
      {/* {listFiles && <AddFiles addFiles={addFiles} />} */}
    </Box>
  );
};

const DocsList = ({ ...rest }: ownProps): JSX.Element => {
  // const data = React.useMemo(() => makeData(36), []);

  return <List {...rest} />;
};

export default DocsList;
