import * as yup from 'yup';

const isObjectNotEmpty = (obj: any) => Object.keys(obj).length > 0;

export const schemaAddMemberToStartup = {
  initial: {
    user: { value: '', label: '', avatar: '', email: '' },
    role: '',
  },
  validators: yup.object({
    user: yup
      .object()
      .shape({
        value: yup.string().required('Campo obrigatório'),
        label: yup.string().required(),
        avatar: yup.string().nullable(),
        email: yup.string().email('Email inválido'),
      })
      .required('Campo obrigatório')
      .test('is-not-empty', 'User cannot be empty', isObjectNotEmpty),
    role: yup
      .string()
      .required('Campo obrigatório')
      .oneOf(['MEMBER', 'LEADER']),
  }),
};
