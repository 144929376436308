import { Avatar, Box, Divider, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { iStartup } from '~/domain/interfaces/models';
import { GetOverviewStartup } from '~/domain/usecases/startup/remote';
import { makeRemoteGetOverviewStartup } from '~/main/factories/usecases/startup/GetOverviewStartupFactory';
import { Button } from '~/presentation/components/UI';
import { Label } from '~/presentation/components/UI/Label';
import { Typography } from '~/presentation/components/UI/Typography';
import startup from '~/presentation/pages/startup';
import { getRoleByName } from '~/utils/getRoleByName';
import AddMemberToStartup from '../EditStartup/AddMemberToStartup';

interface iUser {
  value: string;
  label: string;
  role: string;
  avatar?: string;
  email: string;
}

interface ownProps {
  startup?: iStartup['records'][0];
  mainLeader?: iStartup['records'][0]['members'][0];
  overview?: GetOverviewStartup.Model;
  edit?: boolean;
  members?: iUser[];
}

const TeamTab = ({
  startup,
  mainLeader,
  overview,
  edit,
  members,
}: ownProps) => {
  const [openAddMember, setOpenAddMember] = React.useState(false);
  return (
    <Flex h='503px' gap='32px'>
      <Flex
        flexDirection={'column'}
        flex='1'
        maxW='440px'
        maxH={'503px'}
        overflow={'auto'}
      >
        <Flex justify='space-between' align={'end'} mb='20px'>
          <Typography variant='h6_Inter_SemiBold_14px'>
            Equipe ({startup?.members.length})
          </Typography>

          {edit && (
            <Button
              variant='link'
              color='blue.2'
              padding={'0px'}
              height={'fit-content'}
              onClick={() => {
                setOpenAddMember((prev) => !prev);
              }}
            >
              Adicionar à equipe
            </Button>
          )}
        </Flex>
        {startup?.members
          .map((member) => ({
            value: member.user_.id.toString(),
            label: member.user_.fullname,
            role: member.role,
            avatar: member.user_.avatar,
            email: member.user_.email,
          }))
          .concat(
            members?.map((member) => ({
              ...member,
              avatar: member.avatar ?? '',
            })) ?? []
          )
          .slice()
          .sort((a, b) => {
            if (getRoleByName(a.role) === 'Gestor') return -1;
            if (getRoleByName(b.role) === 'Gestor') return 1;
            return 0;
          })
          .map((member) => {
            return (
              <Flex
                key={member.value}
                flexDirection={'row'}
                gap='60px'
                maxWidth={'min-content'}
                minW={'100%'}
                justifyContent={'space-between'}
                color='black.2'
                mb='12px'
                pl='6px'
              >
                <Flex gap='12px'>
                  <Avatar
                    borderRadius={'5px'}
                    w='40px'
                    h='40px'
                    name={member.label}
                    src={member.avatar}
                    borderWidth='1px'
                    borderColor='black.5'
                  />
                  <Box>
                    <Typography variant='h7_Inter_Medium_14px'>
                      {member.label}
                    </Typography>
                    <Typography variant='b1_Inter_Regular_14px' maxW='225px'>
                      {member.email}
                    </Typography>
                  </Box>
                </Flex>
                <Typography
                  variant='b3_Inter_Medium_12px'
                  h='25px'
                  color='black.2'
                  bg='white.5'
                  borderRadius='3px'
                  px='10px'
                  py='5px'
                >
                  {member.value === mainLeader?.user_.id.toString()
                    ? 'Responsável'
                    : getRoleByName(member.role)}
                </Typography>
              </Flex>
            );
          })}
      </Flex>
      <Divider
        orientation='vertical'
        borderColor='white.4'
        borderWidth='1px'
        height='100%'
      />
      {overview && (
        <Flex flexDirection='column'>
          {[
            {
              label: 'Distribuição por gênero',
              data: overview?.team.gender as Record<string, number>,
              categories: {
                male: 'Masculino',
                female: 'Feminino',
                other: 'Outro',
              },
            },
            {
              label: 'Distribuição por faixa etária',
              data: overview?.team.age as Record<string, number>,
              categories: {
                group1: '18 - 24 anos',
                group2: '25 - 30 anos',
                group3: '31 - 40 anos',
                group4: '41 - 50 anos',
                group5: '51 - 60 anos',
                group6: '61+ anos',
              },
            },
            {
              label: 'Distribuição por papel',
              data: overview?.team.role as Record<string, number>,
              categories: { leader: 'Gestores', member: 'Membros' },
            },
          ].map(({ label, data, categories }) => (
            <Flex key={label} flexDirection='column'>
              <Label>{label}</Label>
              <Flex flexDirection='column' mt='20px' mb='12px' color='black.2'>
                {Object.entries(categories).map(([key, title]) => (
                  <Typography key={key} variant='b1_Inter_Regular_14px'>
                    {title} ({(data?.[key] || 0) * 100}%)
                  </Typography>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
      {edit && (
        <AddMemberToStartup
          isOpen={openAddMember}
          onClose={() => {
            setOpenAddMember((prev) => !prev);
          }}
          startup={startup}
        />
      )}
    </Flex>
  );
};

export default TeamTab;
