import {
  Box,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IconAttention } from '~/presentation/base/icons';
import { Button } from '../../UI/Button'; 
import { Typography } from '~/presentation/components/UI/Typography';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  question: string | React.ReactNode;
  description?: string;
  action: () => void;
  actionText: string;
  actionTextLoading?: string;
  cancelActionText?: string;
  long?: boolean;
}

const WarningModal = ({
  isOpen,
  onClose,
  title,
  question,
  description,
  action,
  actionText,
  actionTextLoading,
  cancelActionText,
  long
}: ownProps): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!isOpen) setLoading(false);
  }, [isOpen]);

  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        isCentered={!isMobile}
        size='full'
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent
          bg='#FDFDFD'
          maxW={ long ? { base: '470px'} : { base: '100%', md: '408px' }}
          minW={ long ? { base: '470px'} : { base: '100%', md: '470px' }}
          minH={'auto'}
          position={{ base: 'fixed', md: 'inherit' }}
          bottom={{ base: '0', md: 'auto' }}
          marginTop='auto'
          borderTopRadius={{ base: '10px', md: '10px' }}
          borderBottomRadius={{ base: 'none', md: '10px' }}
        >
          <ModalHeader p='20px 24px' whiteSpace='nowrap'>
            <Flex
              flexDirection='row'
              justifyContent={'space-between'}
              alignItems='center'
            >
              <Typography variant='h3_Inter_Bold_18px'>
                {title}
              </Typography>
              <ModalCloseButton
                sx={{
                  fontSize: '14px',
                }}
                position={'inherit'}
                color={'#222222'}
              ></ModalCloseButton>
            </Flex>
          </ModalHeader>
          <ModalBody p='0px 24px 20px 24px'>
            <Flex flexDir={'column'} justify='space-between'>
              <Flex flexDir='column' gap='20px'>
                <Typography variant='b1_Inter_Regular_14px'>
                  {question}
                </Typography>
                {description && (
                  <Flex
                    backgroundColor='#FFEFEA'
                    borderRadius='5px'
                    padding='16px 12px'
                    flexDir={'row'}
                    gap='12px'
                  >
                    <Box>
                      <IconAttention />
                    </Box>
                    <Flex flexDir={'column'} gap='12px'>
                      <Text
                        fontStyle='bold'
                        fontWeight='700'
                        fontSize='sm'
                        color='#C23422'
                      >
                        Atenção{' '}
                        <Text
                          fontStyle='normal'
                          fontWeight='400'
                          fontSize='sm'
                          color='#C23422'
                        >
                          {description}
                        </Text>
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter
            p='20px 24px'
            justifyContent='space-between'
            alignItems='center'
            gap={{ base: '69px', md: '10px' }}
            borderTop='1px solid #CECECE'
            flexDir={{ base: 'column', md: 'row' }}
          >
            {isMobile ? (
              <>
                <Button
                  width={{ base: '100%', md: 'fit-content' }}
                  variant='destructive' 
                  onClick={() => {
                    action();
                    setLoading(true);
                  }}
                  isLoading={loading}
                  loadingText={actionTextLoading}
                  fontWeight='500'
                  size="md"
                >
                  <Typography variant='h7_Inter_Medium_14px'>{actionText}</Typography>
                </Button>
                <Button
                  width={{ base: '100%', md: 'fit-content' }}
                  variant='secondary' 
                  onClick={() => onClose()}
                  fontWeight='medium'
                  size="md"
                >
                  <Typography variant='h7_Inter_Medium_14px'>{cancelActionText ? cancelActionText : 'Cancelar'}</Typography>
                </Button>
              </>
            ) : (
              <>
                <Button
                  width={{ base: '100%', md: 'fit-content' }}
                  variant='secondary'  
                  onClick={() => onClose()}
                  fontWeight='medium'
                  size="md"
                >
                  <Typography variant='h7_Inter_Medium_14px'>{cancelActionText ? cancelActionText : 'Cancelar'}</Typography>
                </Button>
                <Button
                  width={{ base: '100%', md: loading ? '120px' : 'fit-content' }}
                  variant='destructive' 
                  onClick={() => {
                    action();
                    setLoading(true);
                  }}
                  isLoading={loading}
                  loadingText={actionTextLoading}
                  fontWeight='500'
                  size="md"
                >
                  <Typography variant='h7_Inter_Medium_14px'>{actionText}</Typography>
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WarningModal;
