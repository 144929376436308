import React from 'react';
import { Box, Divider, Flex, VStack } from '@chakra-ui/react';
import cepPromise from 'cep-promise';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import cidadesPB from '~/utils/cidadesPB';
import { Input } from '../../UI/NewInput';
import { Select } from '../../UI/NewSelect';
import FormItem from '../../UI/FormFormik/formItem';
import { Field, useFormikContext } from 'formik';
import CameraButton from '../../Modals/startup/RegisterStartup/StartupPicture';

export interface CepResponse {
  street: string;
  city: string;
  neighborhood: string;
  state: string;
}

interface FormRegisterStartupProps {
  formik?: any;
  onFileChange?: (file: File) => void;
}

const FormRegisterStartup = ({
  formik,
  onFileChange,
}: FormRegisterStartupProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [cepResponse, setCepResponse] = React.useState<CepResponse | undefined>(
    undefined
  );

  const { records } = useSelector((store: iStore) => store.activity);

  const activityOptions = records.map((activity) => ({
    value: activity.id,
    label: activity.name,
  }));
  const genderOptions = [
    { value: 'MALE', label: 'Masculino' },
    { value: 'FEMALE', label: 'Feminino' },
    { value: 'OTHER', label: 'Outro' },
  ];
  const cityOptions = cidadesPB.map((city) => ({
    value: city,
    label: city,
  }));

  function isValidCEP(cepInput: string) {
    return /^[0-9]{5}-[0-9]{3}$/.test(cepInput);
  }

  const handleOnBlur = async () => {
    const cepValue = values.cep;
    if (isValidCEP(cepValue)) {
      try {
        const cepResponseData = await cepPromise(cepValue);
        setCepResponse(cepResponseData);
        setFieldValue(
          'address',
          `${cepResponseData.street}, ${cepResponseData.neighborhood}`
        );
        const matchingCityOption = cityOptions.find(
          (option) => option.value === cepResponseData.city
        ) || { value: cepResponseData.city, label: cepResponseData.city };
        setFieldValue('city', matchingCityOption);
      } catch {
        setCepResponse(undefined);
      }
    } else {
      setCepResponse(undefined);
    }
  };

  const handleActivityChange = (val: { value: any }) => {
    const selectedValue = val ? val.value : '';
    formik.setFieldValue('activity', Number(selectedValue));
  };

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      justifyContent='space-between'
      width='100%'
      gap='30px'
      overflow={{ base: 'auto', md: 'initial' }}
      maxHeight={{ base: '500px', md: 'none' }}
    >
      <VStack spacing='4' width={{ base: '100%', md: '80px' }}>
        <CameraButton onChange={onFileChange} />
      </VStack>

      <Divider orientation='vertical' />

      <VStack spacing='4' width={{ base: '100%', md: '280px' }}>
        <FormItem name='name'>
          <Field
            as={Input}
            id='name'
            name='name'
            label='Nome da Startup'
            isRequired
          />
        </FormItem>
        <FormItem name='address'>
          <Field
            as={Input}
            id='address'
            name='address'
            label='Endereço'
            isOptional
          />
        </FormItem>
        <FormItem name='website'>
          <Field
            as={Input}
            id='website'
            name='website'
            label='Site'
            placeholder='https://'
            isOptional
          />
        </FormItem>
        <FormItem name='nameManager'>
          <Field
            as={Input}
            id='nameManager'
            name='nameManager'
            label='Nome do responsável'
            isRequired
          />
        </FormItem>
        <FormItem name='emailManager'>
          <Field
            as={Input}
            id='emailManager'
            name='emailManager'
            label='E-mail do responsável'
            isRequired
          />
        </FormItem>
        <FormItem name='roleManager'>
          <Field
            as={Input}
            id='roleManager'
            name='roleManager'
            label='Função do responsável'
            placeholder='ex.: Gerente Administrativo'
            isOptional
          />
        </FormItem>
      </VStack>

      <VStack spacing='4' width={{ base: '100%', md: '180px' }}>
        <Box minW='100%'>
          <FormItem name='activity'>
            <Field
              as={Select}
              id='activity'
              name='activity'
              label='Segmento'
              options={activityOptions}
              placeholder='Escolha uma opção'
              isRequired
              onChange={handleActivityChange}
            />
          </FormItem>
        </Box>
        <Box minW='100%'>
          <FormItem name='city'>
            <Field
              as={Select}
              id='city'
              name='city'
              label='Cidade'
              placeholder='Escolha uma opção'
              options={cityOptions}
              isSearchable
              isRequired
            />
          </FormItem>
        </Box>
        <FormItem name='instagram'>
          <Field
            as={Input}
            id='instagram'
            name='instagram'
            label='Instagram'
            placeholder='@'
            isOptional
          />
        </FormItem>
        <Box minW='100%'>
          <FormItem name='gender'>
            <Field
              as={Select}
              id='gender'
              name='gender'
              label='Gênero'
              options={genderOptions}
              placeholder='Escolha uma opção'
              isRequired
            />
          </FormItem>
        </Box>
        <FormItem name='phoneManager'>
          <Field
            as={Input}
            id='phoneManager'
            name='phoneManager'
            label='Telefone'
            mask='phone'
            placeholder='(__) _ ____-____'
            isOptional
          />
        </FormItem>
        <FormItem name='cargaHoraria'>
          <Field
            as={Input}
            id='cargaHoraria'
            name='cargaHoraria'
            label='Carga horária'
            isOptional
          />
        </FormItem>
      </VStack>

      <VStack spacing='4' width={{ base: '100%', md: '180px' }}>
        <FormItem name='cnpj'>
          <Field
            as={Input}
            id='cnpj'
            name='cnpj'
            label='CNPJ'
            mask='cnpj'
            placeholder='__.___.___/____-__'
            isOptional
          />
        </FormItem>
        <FormItem name='cep'>
          <Field
            as={Input}
            id='cep'
            name='cep'
            label='CEP'
            placeholder='_____-___'
            mask='cep'
            isOptional
            onBlurCapture={handleOnBlur}
          />
        </FormItem>
      </VStack>
    </Flex>
  );
};

export default FormRegisterStartup;
