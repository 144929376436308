import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef } from 'react';
import DocsList from '~/presentation/components/DocsList';
import Tab from '~/presentation/components/Tab';
import { useLocation } from 'react-router';
import { makeApiUrl } from '~/main/factories/http';
import axios from 'axios';
import { makeReduxListFolderContentDocument } from '~/main/factories/usecases/document/ListFolderContentDocumentFactory';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { useDropzone } from 'react-dropzone';
import isUserRole from '~/utils/isUserRole';

const Documents = (): JSX.Element => {
  const { accessToken } = useSelector((store: iStore) => store.auth);
  const [path, setPath] = React.useState<{ name: string; id: number }[]>([
    isUserRole(['MENTOR'])
      ? {
          name: 'INCUBADORA',
          id: 0,
        }
      : {
          name: 'STARTUPS',
          id: 0,
        },
  ]);
  const [active, setActive] = React.useState<
    'STARTUPS' | 'INCUBADORA' | 'MENTORIA' | 'ENTREGAS'
  >(isUserRole(['MENTOR']) ? 'INCUBADORA' : 'STARTUPS');
  const [canRegister, setCanRegister] = React.useState<boolean>(false);
  const [loadingContent, setLoadingContent] = React.useState(true);
  const { state } = useLocation<{ path: { name: string; id: number }[] }>();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (state) {
      setPath(state.path);
      setActive(
        state.path[0].name as
          | 'STARTUPS'
          | 'INCUBADORA'
          | 'MENTORIA'
          | 'ENTREGAS'
      );
    }
  }, [state]);

  const renderTabs = () => {
    const baseTabs = [{ title: 'INCUBADORA' }, { title: 'MENTORIA' }];
    const memberTabs = [{ title: 'STARTUPS' }, ...baseTabs];
    const allTabs = [...memberTabs, { title: 'ENTREGAS' }];

    return isUserRole(['MENTOR'])
      ? baseTabs
      : isUserRole(['MEMBER'])
      ? memberTabs
      : allTabs;
  };

  const changePath = (name: string, id: number) => {
    const idx = path.findIndex((el) => el.name === name);

    if (idx > -1) {
      setPath(path.slice(0, idx + 1));
    }
  };

  const setLoading = async (state: boolean) => {
    const sleep = (ms: number | undefined) =>
      new Promise((r) => setTimeout(r, ms));

    state ? setLoadingContent(state) : await sleep(500),
      setLoadingContent(state);
  };

  const addFiles = async (
    files: File[],
    path: { name: string; id: number }[]
  ) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('folder', path[path.length - 1].id.toString());

    setLoading(true);

    await axios
      .post(makeApiUrl('/files/upload'), formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(() => {
        makeReduxListFolderContentDocument().listFolderContent({
          id: path[path.length - 1].id,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*** Add Files Button */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles.length) {
        addFiles(acceptedFiles, path);
        /* setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]); */
      }
    },
    [path]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDrag: true,
    accept: { 'application/pdf': ['.pdf'] },
  });
  /*** End Add Files Button */

  return (
    <Box flex='1' h='calc(100% - 112px)' m='8' mt={0}>
      <Breadcrumb mb='8'>
        {path.map((item) => {
          return (
            <BreadcrumbItem
              key={item.name}
              onClick={() => {
                changePath(item.name, item.id);
              }}
            >
              <BreadcrumbLink>{item.name}</BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>

      <Tab
        active={active}
        setActive={setActive}
        tabs={renderTabs()}
        changePath={changePath}
        buttonText='Novo Documento'
        canRegister={canRegister}
        searchPlaceholder='Pesquisar documentos...'
        action={function (): void {
          buttonRef.current?.click();
        }}
      />
      <button
        type='button'
        style={{ display: 'none' }}
        {...getRootProps()}
        ref={buttonRef}
      >
        <input {...getInputProps()} />
      </button>
      <DocsList
        active={active}
        path={path}
        setPath={setPath}
        setCanRegister={setCanRegister}
        setLoading={setLoading}
        loadingContent={loadingContent}
      />
    </Box>
  );
};

export default Documents;
