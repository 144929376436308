import { forwardRef, useCallback } from 'react';
import { SelectProps } from './types';

import { Box } from '@chakra-ui/react';
import { Typography } from '../Typography';

import { DropdownIndicator } from './dropdownIndicator';
import { Option } from './option';

import { useField, useFormikContext } from 'formik';
import ReactSelect, { OptionProps, StylesConfig } from 'react-select';
import { theme } from '../../../base/themes/theme';

type CustomStylesProps = {
  size: 'mini' | 'default';
}

const customStyles = ({ size }: CustomStylesProps): StylesConfig => {
  const isMini = size === 'mini';
  return {
    container(base, props) {
      return {
        ...base,
        flex: 1,
        width: '100%',
        ...(props.isFocused && {
          borderRadius: '4px',
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineColor: theme.colors.blue[1],
          boxShadow: theme.shadows.focus,
        }),
        ...(props.isDisabled && {
          borderColor: theme.colors.black[4],
          backgroundColor: theme.colors.white[2],
        }),
      };
    },
    placeholder(base, props) {
      return {
        ...base,
        color: theme.colors.black[4],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      };
    },
    menu(base, props) {
      return {
        ...base,
        backgroundColor: theme.colors.white[1],
        color: theme.colors.white[5],
        border: '1px solid',
        borderColor: theme.colors.black[5],
        borderRadius: '4px',
        boxShadow: 'none',
      };
    },
    menuPortal: (base, props) => ({
      ...base,
      zIndex: '9999 !important',
    }),
    control(base, props) {
      return {
        ...base,
        width: '100%',
        border: '1px solid',
        backgroundColor: theme.colors.white[1],
        borderColor: `${theme.colors.black[5]} !important`,
        borderRadius: '4px',
        padding: '0',
        minHeight: isMini ? '20px' : '40px',
        boxShadow: 'none',
        ...(props.isFocused && {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineColor: theme.colors.blue[5],
        }),
        '&:hover': {
          borderColor: theme.colors.black[4],
          backgroundColor: theme.colors.white[2],
          cursor: 'pointer',
        },
        ...(props.isDisabled && {
          pointerEvents: 'none',
          borderColor: `${theme.colors.black[4]} !important`,
          backgroundColor: theme.colors.white[2],
        }),
      };
    },
  }
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, name, isRequired, isOptional, size = "default", ...props }, ref) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name || '');

    const isMini = size === 'mini';

    const renderOption = useCallback((props: OptionProps) => {
      return <Option {...props} size={size}  />;
    }, []);

    return (
      <Box
        gap={isMini ? 1 : 3}
        display='flex'
        flexDirection='column'
        width={isMini ? 'auto' : 'full'}
        minW={isMini ? 'auto' : '10rem'}
      >
        {label && (
          <Box display='flex' alignItems='center'>
            <Typography variant='h6_Inter_SemiBold_14px'>{label}</Typography>
            {isRequired && (
              <Typography variant='h6_Inter_SemiBold_14px' color='#C23422'>
                *
              </Typography>
            )}
            {isOptional && (
              <Typography variant='b2_Inter_Regular_14px'>
                &nbsp;
                {'(opcional)'}
              </Typography>
            )}
          </Box>
        )}
        <ReactSelect
          menuPortalTarget={document.body}
          isClearable={false}
          noOptionsMessage={() => 'Não há opções'}
          isSearchable={false}
          styles={customStyles({ size })}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option: (props) => renderOption(props),
          }}
          {...props}
          {...field}
          onChange={(val) => {
            setFieldValue(field.name, val);
          }}
        />
      </Box>
    );
  }
);
Select.displayName = 'Select';
