import {
  Button,
  Box,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Flex,
  HStack,
  Divider,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Avatar,
} from '@chakra-ui/react';
import { IconMockedStartup1, IconPlus } from '~/presentation/base/icons';
import { schemaRegisterMember } from '~/validators/RegisterMember/RegisterMemberValidator';
import { useFormik } from 'formik';
import { History } from '~/main/routes';
import React, { Component, useContext, useEffect } from 'react';
import FormRegisterStartup from '../Form/RegisterStartup/FormRegisterStartup';
import ConfirmationModal from './EnrollmentConfirmationModal';
import { schemaRegisterStartup } from '~/validators/RegisterStartup/RegisterStartupValidator';
import { makeRemoteCreateStartup } from '~/main/factories/usecases/startup/CreateStartupFactory';
import { makeReduxListStartup } from '~/main/factories/usecases/startup/ListStartupFactory';
import EnrollmentConfirmationModal from './EnrollmentConfirmationModal';
import UploadDocs from '../UploadDocs';
import { iNotice, iStartup, iStore } from '~/domain/interfaces/models';
import { makeRemoteCreateApplication } from '~/main/factories/usecases/application/CreateApplicationFactory';
import { ConfirmModalContext } from '~/presentation/context/confirmModal';
import { useSelector } from 'react-redux';
import getDate from '~/utils/getDate';
import StringMask from '~/utils/getStringMask';
import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';

interface ownProps {
  isOpen: boolean;
  onClose: () => void;
  notice?: iNotice['records'][0];
}

const NoticeEnrollment = ({
  isOpen,
  onClose,
  notice,
}: ownProps): JSX.Element => {
  const { initial, validators } = schemaRegisterStartup;
  const [files, setFiles] = React.useState<File[]>([]);
  /* const [selectedStartup, setSelectedStartup] =
    React.useState<iStartup['records'][0]>(); */
  const { startups, accessToken } = useSelector((store: iStore) => store.auth);
  const userLogged = useSelector((store: iStore) => store.auth);
  // const { records } = useSelector((store: iStore) => store.startup);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { selectedStartup: workspaceSelectedStartup } = useSelector(
    (store: iStore) => store.auth
  );

  /* 
  useEffect(() => {
    if (startups?.length) {
      /* const __startup = records?.find((el) => {
        return el.id === startups[0].id;
      }); */

  /* if (__startup) {
        setSelectedStartup(__startup);
      } */
  // eslint-disable-next-line no-console
  /* console.log('selectedStartup', selectedStartup);
      // eslint-disable-next-line no-console
      console.log('userLogged', userLogged);
    } 
  }, [startups, userLogged]); */

  const submmitApplication = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    setIsLoading(true);

    await axios
      .post(makeApiUrl('/files/upload'), formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        const f = res.data.map((el: any) => el.id);
        notice &&
          workspaceSelectedStartup &&
          makeRemoteCreateApplication()
            .create({
              body: {
                notice: notice?.id,
                startup: workspaceSelectedStartup.id,
                status: 'SUBMITTED',
                files: f,
              },
            })
            .then(() => {
              setIsLoading(false);
              showConfirmModal('SUBMMIT_APPLICATION');
            })
            .catch((error) => {
              showConfirmModal('APPLICATION_ERROR');
              onClose();
              // eslint-disable-next-line no-console
              console.log('console Catch Error', error);
            });
      });
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size='3xl' isCentered>
        <ModalOverlay />
        <ModalContent maxW={'780px'}>
          <ModalHeader>
            Inscrever-se em um edital <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Divider
              orientation='horizontal'
              display='inline-flex'
              width='100%'
              position='relative'
              marginBottom={'20px'}
            />
            <Flex
              flexDir={'row'}
              justifyContent='space-between'
              alignItems={'flex-start'}
              gap='32px'
            >
              <Stack gap={'20px'}>
                <HStack spacing='4'>
                  <FormControl alignSelf={'flex-start'}>
                    <FormLabel
                      fontWeight='700'
                      fontSize='sm'
                      color='#303950'
                      whiteSpace={'nowrap'}
                    >
                      Identificação
                    </FormLabel>
                    <Text maxW={'147px'} fontSize='sm' color={'#60656D'}>
                      {`${notice?.code} ${notice?.title}`}
                    </Text>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontWeight='700'
                      fontSize='sm'
                      color='#303950'
                      whiteSpace={'nowrap'}
                    >
                      Período das inscrições
                    </FormLabel>
                    <Text maxW={'110px'} fontSize='sm' color={'#60656D'}>
                      {`De ${getDate(notice?.activitiesStartAt)} até ${getDate(
                        notice?.activitiesEndAt
                      )}`}
                    </Text>
                  </FormControl>
                </HStack>
                <HStack>
                  <FormControl>
                    <FormLabel
                      fontWeight='700'
                      fontSize='sm'
                      color='#303950'
                      whiteSpace={'nowrap'}
                    >
                      Valor por projeto
                    </FormLabel>
                    <Text maxW={'147px'} fontSize='sm' color={'#60656D'}>
                      {`R$ ${notice?.value}`}
                    </Text>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontWeight='700'
                      fontSize='sm'
                      color='#303950'
                      whiteSpace={'nowrap'}
                    >
                      Vagas
                    </FormLabel>
                    <Text maxW={'110px'} fontSize='sm' color={'#60656D'}>
                      {`${notice?.vacancies}`}
                    </Text>
                  </FormControl>
                </HStack>
                <HStack>
                  <FormControl>
                    <FormLabel
                      fontWeight='700'
                      fontSize='sm'
                      color='#303950'
                      whiteSpace={'nowrap'}
                    >
                      Objeto do edital
                    </FormLabel>
                    <Text
                      maxW={'100%'}
                      maxH='80px'
                      fontSize='sm'
                      color={'#60656D'}
                    >
                      {`${notice?.description}`}
                    </Text>
                  </FormControl>
                </HStack>
                <Divider
                  orientation='horizontal'
                  display='inline-flex'
                  width='100%'
                  position='relative'
                />
                <HStack spacing='4' alignItems={'center'}>
                  <FormControl>
                    <Stack justifyContent={'space-between'}>
                      <FormLabel
                        fontWeight='700'
                        fontSize='sm'
                        color='#303950'
                        whiteSpace={'nowrap'}
                      >
                        Startup
                      </FormLabel>
                      <HStack>
                        <Avatar
                          name={workspaceSelectedStartup?.name ?? ''}
                          src={workspaceSelectedStartup?.logo ?? ''}
                        ></Avatar>{' '}
                        <Text
                          fontSize='sm'
                          color={'#60656D'}
                          whiteSpace={'nowrap'}
                        >
                          {workspaceSelectedStartup?.name ?? ''}
                        </Text>
                      </HStack>
                    </Stack>
                  </FormControl>
                  <FormControl alignSelf={'flex-start'}>
                    <Stack justifyContent={'space-between'}>
                      <FormLabel
                        fontWeight='700'
                        fontSize='sm'
                        color='#303950'
                        whiteSpace={'nowrap'}
                      >
                        CNPJ
                      </FormLabel>
                      <Text
                        fontSize='sm'
                        color={'#60656D'}
                        whiteSpace={'nowrap'}
                        style={{ marginTop: '20px' }}
                      >
                        {new StringMask('00.000.000/0000-00').apply(
                          userLogged.startups?.at(0)?.cnpj ?? ''
                        )}
                      </Text>
                    </Stack>
                  </FormControl>
                </HStack>
                {/* <HStack alignItems={'flex-start'}> */}
                {/* AGUARDAR ALTERAÇÃO NO BACK PARA PEGAR OS DADOS */}
                {/* <FormControl>
                    <FormLabel fontWeight='700' fontSize='sm' color='#303950'>
                      Gestor responsável
                    </FormLabel>
                    <Text fontSize='sm' color={'#60656D'}>
                      {
                        selectedStartup?.members
                          .filter((member) => member.major === true)
                          .at(0)?.user_.fullname
                      }
                    </Text>
                  </FormControl> */}
                {/* <FormControl>
                    <FormLabel
                      fontWeight='700'
                      fontSize='sm'
                      color='#303950'
                      whiteSpace={'nowrap'}
                    >
                      CPF
                    </FormLabel>
                    <Text maxW={'110px'} fontSize='sm' color={'#60656D'}>
                      {new StringMask('000.000.000-00').apply(
                        selectedStartup?.members
                          .filter((member) => member.major === true)
                          .at(0)?.user_?.cpf
                      )}
                    </Text>
                  </FormControl> */}
                {/* </HStack> */}
              </Stack>
              <Stack gap={'10px'}>
                <FormControl isRequired>
                  <FormLabel
                    fontFamily='Inter'
                    fontStyle='normal'
                    fontWeight='700'
                    fontSize='sm'
                    color='#303950'
                    whiteSpace={'nowrap'}
                  >
                    Documentos associados
                  </FormLabel>
                </FormControl>
                <UploadDocs
                  externalFiles={files}
                  onChange={(files: File[]) => {
                    setFiles(files);
                  }}
                />
              </Stack>
            </Flex>
            <Divider
              orientation='horizontal'
              display='inline-flex'
              width='100%'
              position='relative'
            />
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent='space-between' w='100%' spacing='4'>
              <Button
                fontWeight={'normal'}
                variant='outline'
                color='#303950'
                borderColor='#303950'
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
              {
                <Button
                  id='submit'
                  bg='#303950'
                  _hover={{ background: '#788FC9' }}
                  _active={{ background: '#303950' }}
                  color='white'
                  isLoading={isLoading}
                  disabled={isLoading || files.length === 0}
                  loadingText=' Inscrevendo-se'
                  fontWeight='500'
                  fontSize='14px'
                  onClick={() => {
                    submmitApplication();
                    onClose();
                  }}
                >
                  Inscrever-se
                </Button>
              }
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NoticeEnrollment;
