import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Chip } from '~/presentation/components/Chip';
import { Typography } from '~/presentation/components/UI/Typography';

type UsersInvitedProps = {
  eventId: number;
};

export const UsersInvited: React.FC<UsersInvitedProps> = ({
  eventId,
}: UsersInvitedProps) => {
  const { records: events } = useSelector((store: iStore) => store.event);
  const event = events.find((event) => event.id === eventId);

  return (
    <Stack spacing={3}>
      <Typography variant='h7_Inter_Medium_14px'>Convidados</Typography>
      <Box display={'flex'} flexWrap={'wrap'} gap={2}>
        {event?.users
          .map((user) => (
            <Chip key={user.id}
              info={{
                ...user,
                logo: user.avatar,
              }}
            />
          ))}
      </Box>
    </Stack>
  );
};
