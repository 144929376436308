import { Box } from '@chakra-ui/react';
import React from 'react';
import { theme } from '../../../base/themes/theme';

import { CheckIcon } from '@chakra-ui/icons';
import { OptionProps } from 'react-select';

type Props = {
  size?: 'mini' | 'default';
} & OptionProps;

export const Option: React.FC<Props> = ({
  innerProps,
  isFocused,
  isSelected,
  label,
  size = 'default',
}) => {


  const isMini = size === 'mini';

  return (
    <Box
      p={isMini ? 1 : 3}
      {...innerProps}
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      color='black.1'
      bg='white.1'
      cursor='pointer'
      fontSize='14px'
      sx={{
        '&:not(:last-child)': {
          borderBottom: '1px solid',
          borderBottomColor: theme.colors.black[5],
        },
        '&:hover': {
          backgroundColor: theme.colors.white[2],
        },
        ...(isFocused && {
          backgroundColor: theme.colors.white[2],
        }),
      }}
    >
      <Box w={2} h={2} mr={3} display='flex' alignItems='center'>
        {isSelected && <CheckIcon />}
      </Box>
      {label}
    </Box>
  );
};
